import {
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

import { DbService } from 'src/app/services/db.service';

import {
  ModalController,
  Platform,
} from '@ionic/angular';

@Component({
  selector: 'app-register',
  templateUrl: './register.page.html',
  styleUrls: ['../login/login.page.scss','./register.page.scss'],
})
  
export class RegisterPage implements OnInit {
  // @Input() type;

  ismobile;
  constructor(public db:DbService,private router:Router,private platform:Platform,public modalCtrl:ModalController) {
    
  }

  ngOnInit() {
    this.ismobile = this.db.checkmobile();
    // this.showform(this.type);
    // console.log(this.db.registerform)
    // if (this.type = undefined) {
    //   this.db.registerform = true;
    // }

    // if (this.ismobile) {
    //   this.db.registerform = true;
    // }

  }

  @HostListener('window:resize', ['$event'])
  private func(){
     this.ismobile = this.db.checkmobile();
  }
     
  // showform(data) {
  //   if(data == 'register'){
  //     // this.db.loginformm = false;
  //     this.db.registerform = true;
  //   } else if(data == 'login') {
  //     this.db.registerform = false;
  //     // this.db.loginformm = true;
  //   }  
  // }

}

