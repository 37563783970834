import { NgModule } from '@angular/core';
import {
  PreloadAllModules,
  RouterModule,
  Routes,
} from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/tabs/home',
    pathMatch: 'full'
  },
  //    redirectTo: '/tabs/home',
  // {
  //   path: '**',
  //    redirectTo: '/'
  // },
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
  },
  {
    path: 'c',
    loadChildren: () => import('./pages/category/category.module').then( m => m.CategoryPageModule)
  },
  {
    path: 'c/:product',
    loadChildren: () => import('./pages/category/category.module').then( m => m.CategoryPageModule)
  },
  {
    path: 'c/:product/:product_1',
    loadChildren: () => import('./pages/category/category.module').then( m => m.CategoryPageModule)
  },
  {
    path: 'c/:product/:product_1/:product_2',
    loadChildren: () => import('./pages/category/category.module').then( m => m.CategoryPageModule)
  },
  {
    path: 'cl/:product',
    loadChildren: () => import('./pages/product-list/product-list.module').then( m => m.ProductListPageModule)
  },
  {
    path: 'cl/:product/:product_1',
    loadChildren: () => import('./pages/product-list/product-list.module').then( m => m.ProductListPageModule)
  },
  {
    path: 'cl/:product/:product_1/:product_2',
    loadChildren: () => import('./pages/product-list/product-list.module').then( m => m.ProductListPageModule)
  },
  {
    path: 'yourcart',
    loadChildren: () => import('./pages/yourcart/yourcart.module').then( m => m.YourcartPageModule)
  },
  {
    path: 'wishlist',
    loadChildren: () => import('./pages/wishlist/wishlist.module').then( m => m.WishlistPageModule)
  },
  {
    path: 'my-account',
    loadChildren: () => import('./pages/my-account/my-account.module').then( m => m.MyAccountPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'pr/:data',
    loadChildren: () => import('./pages/product-detail/product-detail.module').then( m => m.ProductDetailPageModule)
  },
  {
    path: 'popup-attribute',
    loadChildren: () => import('./pages/popup-attribute/popup-attribute.module').then( m => m.PopupAttributePageModule)
  },
  {
    path: 'add-address',
    loadChildren: () => import('./pages/add-address/add-address.module').then( m => m.AddAddressPageModule)
  },
  {
    path: 'address-list',
    loadChildren: () => import('./pages/address-list/address-list.module').then( m => m.AddressListPageModule)
  },
  {
    path: 'my-profile',
    loadChildren: () => import('./pages/my-profile/my-profile.module').then( m => m.MyProfilePageModule)
  },
  // {
  //   path: 'my-profile/my-orders',
  //   loadChildren: () => import('./pages/my-profile/my-profile.module').then( m => m.MyProfilePageModule)
  // },

  // {
  //   path: 'my-profile/:data',
  //   loadChildren: () => import('./pages/my-profile/my-profile.module').then( m => m.MyProfilePageModule)
  // },
  {
    path: 'my-orders',
    loadChildren: () => import('./pages/my-orders/my-orders.module').then( m => m.MyOrdersPageModule)
  },
  {
    path: 'order-detail',
    loadChildren: () => import('./pages/order-detail/order-detail.module').then( m => m.OrderDetailPageModule)
  },
  {
    path: 'order-detail/:order_id',
    loadChildren: () => import('./pages/order-detail/order-detail.module').then( m => m.OrderDetailPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'review-product',
    loadChildren: () => import('./pages/review-product/review-product.module').then( m => m.ReviewProductPageModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./pages/contact-us/contact-us.module').then( m => m.ContactUsPageModule)
  },
  {
    path: 'location',
    loadChildren: () => import('./pages/location/location.module').then( m => m.LocationPageModule)
  },
  {
    path: 'product-popup',
    loadChildren: () => import('./pages/product-popup/product-popup.module').then( m => m.ProductPopupPageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then( m => m.TabsPageModule)
  },
  {
    path: 'filters-popup',
    loadChildren: () => import('./pages/filters-popup/filters-popup.module').then( m => m.FiltersPopupPageModule)
  },
  {
    path: 'forgetten',
    loadChildren: () => import('./pages/forgetten/forgetten.module').then( m => m.ForgettenPageModule)
  },
  {
    path: 'why',
    loadChildren: () => import('./pages/why/why.module').then( m => m.WhyPageModule)
  },
  {
    path: 'story',
    loadChildren: () => import('./pages/story/story.module').then( m => m.StoryPageModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module').then( m => m.CheckoutPageModule)
  },
  {
    path: 'quickview',
    loadChildren: () => import('./pages/quickview/quickview.module').then( m => m.QuickviewPageModule)
  },
  {
    path: 'cancellation-policy',
    loadChildren: () => import('./pages/cancelation-policy/cancelation-policy.module').then( m => m.CancelationPolicyPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule),
  },
  {
    path: 'about-us',
    loadChildren: () => import('./pages/about-us/about-us.module').then( m => m.AboutUsPageModule)
  },
  {
    path: 'terms-condition',
    loadChildren: () => import('./pages/terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'reward-points',
    loadChildren: () => import('./pages/redeem/redeem.module').then( m => m.RedeemPageModule)
  },
  {
    path: 'wallet',
    loadChildren: () => import('./pages/wallets/wallets.module').then( m => m.WalletsPageModule)
  },
  {
    path: 'social-share',
    loadChildren: () => import('./pages/social-share/social-share.module').then( m => m.SocialSharePageModule)
  },
  {
    path: 'blog-list',
    loadChildren: () => import('./pages/blog-list/blog-list.module').then( m => m.BlogListPageModule)
  },
  {
    path: 'blog-list/:id',
    loadChildren: () => import('./pages/blog-list/blog-list.module').then( m => m.BlogListPageModule)
  },
  {
    path: 'blog-detail/:route',
    loadChildren: () => import('./pages/blog-detail/blog-detail.module').then( m => m.BlogDetailPageModule)
  },
  {
    path: 'comments',
    loadChildren: () => import('./pages/comments/comments.module').then( m => m.CommentsPageModule)
  },
  {
    path: 'subscriptions',
    loadChildren: () => import('./pages/subscribe/subscribe.module').then( m => m.SubscribePageModule)
  },
  {
    path: 'subscribe-plan',
    loadChildren: () => import('./pages/subscribe-plan/subscribe-plan.module').then( m => m.SubscribePlanPageModule)
  },
  {
    path: 'startup',
    loadChildren: () => import('./pages/startup/startup.module').then( m => m.StartupPageModule)
  },
  {
    path: 'location-selection',
    loadChildren: () => import('./pages/location-selection/location-selection.module').then( m => m.LocationSelectionPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'order-summary',
    loadChildren: () => import('./pages/order-summary/order-summary.module').then( m => m.OrderSummaryPageModule)
  },
  {
    path: 'thankyou/:order_id',
    loadChildren: () => import('./pages/thankyou/thankyou.module').then( m => m.ThankyouPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, initialNavigation: 'enabled' })

  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
