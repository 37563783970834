import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LazyLoadImageModule } from 'ng-lazyload-image';

import { IonicModule } from '@ionic/angular';

import { WishListComponent } from './wish-list.component';

@NgModule({
  declarations: [WishListComponent],
  imports: [
    CommonModule,
    IonicModule,
    LazyLoadImageModule
  ],
  exports:[WishListComponent]
})
export class WishListModule { }
