import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import {
  Injectable,
  NgZone,
} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';

import { Checkout } from 'capacitor-razorpay';
import { auth } from 'firebase';
import OneSignal from 'onesignal-cordova-plugin';
// import { google } from 'google-maps';
import {
  Observable,
  Subject,
} from 'rxjs';
import { SubscribePlanPage } from 'src/app/pages/subscribe-plan/subscribe-plan.page';

import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
// import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Share } from '@capacitor/share';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  Platform,
} from '@ionic/angular';

import { AddAddressPage } from '../pages/add-address/add-address.page';
import { AddressListPage } from '../pages/address-list/address-list.page';
import { RegisterPage } from '../pages/register/register.page';
import { SocialSharePage } from '../pages/social-share/social-share.page';

// const axios = require('axios');
// declare var google : google;
@Injectable({
  providedIn: 'root'
})


export class DbService {
  // businessId ="BS-00002";
  // baseUrl = "https://demo.go1grocery.com";
  // route= "gogrocery-demo-home-page";
  // route= "tajcottage-homepage";
  //  domain = "kodhaifashions.com";
  // domain = "meatstoday.com";
  // domain = "demo.go1grocery.com";
  // domain = "stage.odipappa.com";
  // domain = "fishbazar.tridotstech.com"
  //  route = `meat-masterz-home-page`
  // domain ="fbnew.tridotstech.com"
  // domain = "meatsmasters.tridotstech.com"
  //   route = `meat-masterz-home-page`
  // route = `fish-bazar-landing`;


  // domain = "meatton.tridotstech.com"
  domain = "stagemeatton.tridotstech.com"
  oneSignalId = `8aef132f-46eb-4088-9d1f-aee8f845e7db`

  baseUrl = `https://${this.domain}/`;
  baseResource = `https://${this.domain}/api/resource/`;
  baseMethod= `https://${this.domain}/api/method/`;
  route= `home-page`;
  businessId = "BS-00001";

  added_animate = new Subject(); //#Animate
  alert_animate = new Subject();


  ismobile ; //#check mobile
  cust_email;  //#customer Email
  cust_name; //#Customer Name

  website_settings :any = {};
  business_info:any = {};

  // locationpopup = false;
  location_error  = false;
  location;
  location_sugesstions = [];
  city;
  //   latitude; //
  // longitude; //
  location_info:any = { zipcode : '',country :'',state :'',city:'',address:'',latitude:'' ,longitude:''}
  located = new Subject()

  razorpay_settings :any ={};
  razorpay_payment = new Subject();

  modal = false;

  // payment_bank :any = {};



  cart_count = 0; //My cart count
  wish_count = 0; //Wishlist count
  cart_items = []; //Cart  items
  wishcart_items = []; //Wish cart Items
  cart_data :any ={}; // //Cart data
  wishcart = true; //side cart active
  mycart = false; //side cart active
  wish_cart_emit = new Subject(); //wish cart emitt function..
  mycart_emit = new Subject(); //Add to cart emitt functin...,

  address_list:any = []; //address lists
  area_list  = []; //available area list
  selected_address :any ={}; //address list
  httpOptions;
  httpHeaders;
  normal_login = true; //Selected login type

  view = 'Grid View'; //Selected view type
  childs:any={}; //Selected child attributes
  cart_emitted ;

  registerform;
  otp_form = false;


  path; //current router Path
  minimax_price: any = {}; //Filters
  choosed_brands =[]; //choosed category brands
  choosed_attributes =[]; //Choosed category attributes
  choosed_product_id;//choosed produt id
  category = []; //product categories
  rating;

  // sub = new Subject();
  total_amount:any = 0;

  // ####### Orderinfo
  delivery_slots: any = [];
  selected_slot:any = [];
  selected_slot_label : any = {};
  discounts : any = { discount_amount : 0, discount : ''};
  coupon_discounts : any = { discount_amount : 0, discount : '',coupon_code : '',discount_products : [] }

  shipping_methods :any = [];
  shipping_settings:any = { shipping_charges : 0 };
  shipping_selected = new Subject();
  // address_selected = new Subject();
  available_location = false;
  billing_address:any = {}


  // cart_getted = new Subject();
  opened_login_modal;
  modal_popup = true ;//temporary modal popup check
  scroll_event:any = { desktop_header : true,mobile_header:false,no_search_icon:false}; //Scrolling data
  subscription_plan_info_status;
  subscribe_emit = new Subject();


  wallet_detail:any = {};
  loyalty_details : any = {}
  order_items = []; 
  ordered = new Subject();
  dashboard_info : any ={}

  otp_send = new Subject();
  calculate  = new Subject();

  modal_stacks :any = [];
  // tutorial: AngularFireObject<any>;
// db: AngularFireDatabase
  // reloads = false;
  isBrowser;
  constructor(private http: HttpClient, private alertCtrl: AlertController,private platform:Platform,private router:Router,private navCtrl:NavController,private geo:Geolocation,private modalCtrl:ModalController,private fireauth:AngularFireAuth,private ngzone:NgZone,public fire:AngularFirestore,private af:AngularFireDatabase,private loadingCtrl:LoadingController) {
      this.httpHeaders = new HttpHeaders({'withCredentials': 'true'});
      // this.tutorial = db.object('tutorial');
   }


// Check mobile or web
   checkmobile(){
      let width = this.platform.width();
      // console.log("Screen resized..", width);
      if(width > 768){
        this.ismobile = false;
          return false
      } else if(width < 768){
        this.ismobile = true;
        return true;
      }
   }


  // Check web image image
  product_img(data){
      if(data){
        if(data.indexOf('https') == -1){
          return this.baseUrl+data;
        } else if(data.indexOf('https') == 0){
          return data
        }
      }
  }


  img_style(data, type) {
    if (type == 'color') {
      return { 'background': data };
    }else if (type == 'img') {
      return { 'background': 'url(' + this.product_img(data) + ')center center / 100% auto no-repeat', 'background-size': 'contain' };
    }else if (type == 'colorcode') {
      return { 'color': data };
    }else if (type == 'bgcolor') {
      return { 'background': data, 'color': data };
    } else if (type == 'full-bg-img') {
      return { 'background': 'url(' + this.product_img(data) + ') no-repeat', 'background-size': 'contain !important' }
    } else if (type == 'bg-image') {
      return { 'background' : 'url('+ this.product_img(data) +') no-repeat'}
    }
  }


// Add to Wish
addtowish(data) {
  var datas={
    item_code: data.name,
    qty: data.wish_count?data.wish_count:1,
    qty_type:"",
    rate: data.price,
    cartType: "Wishlist",
    customer: localStorage.customerRefId,
    attribute: data.selected_attribute,
    attribute_id: data.ids
  }
 this.insert_cart_items(datas).subscribe(data => {
      this.get_cart_item();
  }, error => {console.log(JSON.stringify(error.json()));});
}


removewish(data) {
   let cartid = '';
   this.wishcart_items.find(res => {
     if (res.product == data.name) {
      cartid = res.name
     }
   })
   var datas={
      name:cartid,
      customer:localStorage.customerRefId,
      user:""
   }
   this.delete_cart_items(datas).subscribe(data => {
       this.get_cart_item()
   }, error => { console.log(JSON.stringify(error.json()));});
}


      // // !value.ids ?  (value.ids = '' ,   value.selected_attribute = '' ): null
      // if (value.product_attributes && value.product_attributes.length == 0) {
      //   value.ids ='';
      //   value.selected_attribute =''
      //     if(value.count != 0){
      //       this.db.update_cart(value);
      //     } else if(value.count == 0) {
      //       this.db.addtocart(value);
      //     }
      //   } else {
         
      // }


  //addtocart
  async addtocart(value) {
    // !value.ids ? (value.ids ='' ,value.selected_attribute ='') : null
    // if(value.product_attributes && value.product_attributes.length != 0){
      // this.openQuickView(value);
    // } else {
      value.count != 0 ? this.update_cart(value) : this.adding(value)
    // }  
  }



adding(value){
      
      var datas={
          item_code:value.name,
          qty: this.plusCount(value.count),
          qty_type: '',
          rate: value.price,
          cartType:"Shopping Cart",
          customer:localStorage.customerRefId,
          attribute: value.selected_attribute,
          attribute_id: value.ids
      }
      // console.log("add",datas)
      value.temp_disabled = true
      value.disable_add_to_cart_button = 1
      this.insert_cart_items(datas).subscribe(data => {
        value.temp_disabled = false
        value.disable_add_to_cart_button = 0
        if(data.message.status == "failed"){
            this.alert(data.message.message);
        } else {
          this.added_animate.next(value.item);
            if(localStorage.customerRefId == undefined){
              localStorage.customerRefId = data.message.customer
              localStorage.guestRefId = data.message.customer
            }
        }
        this.get_cart_item()
      }, error => { console.log(JSON.stringify(error.json())); });
}


async update_cart(list) {
  this.update_cart_item(list,'add');
}


removecart(value){
    if(!value.selected_cart_id || value.product_attributes.length == 0){
      this.cart_items.map(res =>{
        if(res.product == value.name){
          value.selected_cart_id = res.name
        }
      })
    }

    var datas = { name: value.selected_cart_id,  customer: localStorage.customerRefId,  user:"" }
    this.delete_cart_items(datas).subscribe(data => {
        this.get_cart_item()
      }, error => { console.log(JSON.stringify(error.json()));
    });
}


removetocart(value){
  this.removeCount(value.count) == 0 ?  this.removecart(value) : this.update_cart_item(value,'remove');
}


async update_cart_item(list,type) {
    if(!list.selected_cart_id || list.product_attributes.length == 0){
        this.cart_items.map(res =>{
          if(res.product == list.name){
            list.selected_cart_id = res.name
          }
        })
    }

    list.temp_disabled = true
    list.disable_add_to_cart_button = 1
    
    // var data = {
    //   "product": list.name,
    //   "attribute_id":  list.ids,
    //   "cart_qty": list.count,
    //   "variant_html" : list.selected_attribute
    // }

    // this.validate_attributes_stock(data).subscribe(data => {
    //   if(data.message && data.message.status == "Success"){
    //     list.stock = data.message.stock
    //     list.price = data.message.price,
    //     list.old_price = data.message.old_price
    //     list.discount_label = data.message.discount.discount_label
    //     list.discount_rule = data.message.discount.discount_rule
    //     list.stock_alert_message = undefined

        var datas = {  name: list.selected_cart_id, qty: type =='add'? this.plusCount(list.count):this.removeCount(list.count),  qty_type:"" }
        this.update_cart_items(datas).subscribe(data => {
          list.temp_disabled = false
          list.disable_add_to_cart_button = 0
          if(data.message.status == 'error'){
            var d = JSON.parse(data._server_messages);
            var d1 = JSON.parse(d);
            this.alert(d1.message)
          }  
        this.get_cart_item();
      }, error => { console.log(JSON.stringify(error.json())); });

      // }else if(data.message && data.message.status == 'Failed'){
      //   list.price = data.message.price,
      //   list.old_price = data.message.old_price
      //   list.stock_alert_message = data.message.message
      // }
    // })

}


get_cart_item(){
  if (localStorage.customerRefId != undefined) {
    var datas = { customer: localStorage.customerRefId, user: ""}

      this.get_cart_items(datas).subscribe(data => {
          this.store_cart_datas(data);
          this.mycart_emit.next('getted');
      });
    } else {
      this.mycart_emit.next('getted');
    }
}


store_cart_datas(data){
  if(data.message.cart &&  data.message.cart != null &&  data.message.cart.items.length != 0){
      this.cart_items = data.message.cart.items;
      this.cart_data = data.message;
      this.cart_count = data.message.cart.items.length;
      this.cart_data.getted = false;
    }else{
      this.cart_data = {}
      this.cart_data.getted = true;
      this.cart_items = [];
      this.cart_count = 0;
      this.total_amount = 0;
      this.shipping_settings.selected_shipping_method_name = undefined
      this.selected_slot = [];
    }

    if(data.message.wishlist &&  data.message.wishlist != null && data.message.wishlist.items.length != 0){
        this.wishcart_items = data.message.wishlist.items;
        this.wish_count = data.message.wishlist.items.length;
      }else{
        this.wishcart_items=[];
        this.wish_count = 0;
    }
}


store_customer_info(res){
    localStorage.CustomerId = res.message[0].email;
    this.cust_email = res.message[0].email;
    localStorage.email = res.message[0].email;
    this.cust_name = res.message[0].first_name + ' ' + res.message[0].last_name;
    localStorage.CustomerName = res.message[0].first_name + ' ' + res.message[0].last_name;
    localStorage.api_key = res.message[0].api_key;
    localStorage.api_secret = res.message[0].api_secret;
    localStorage.Customerphone = res.message[0].phone;
    localStorage.Customerfirst_name = res.message[0].first_name
    localStorage.Customerlast_name = res.message[0].last_name
    localStorage.customerRefId = res.message[0].name
    localStorage.customerUser_id = res.message[0].user_id;
    localStorage.removeItem('guestRefId');
    this.address_list = res.message[0].address;
    res.message[0].new_password ? localStorage.CustomerPwd = res.message[0].new_password :  localStorage.CustomerPwd = res.message[0].set_new_password
    // this.sass_login();
    res.message.address && res.message.address.length != 0 ? this.choose_default_address() : null
    console.log(localStorage.player_id)
    localStorage.player_id ? (this.update_onsignal_id().subscribe(res =>{ console.log("one signal id updated..",res)  }) ): null
}



sass_login(usr,pwd){
  var logindata = {  usr: usr,   pwd: pwd, domain:this.domain, "get_user_token": 1   }
    this.sasslogin(logindata).subscribe(data => {
      if(data.message.api_key) {
        localStorage.api_key = data.message.api_key;
        localStorage.api_secret = data.message.api_secret;
      }
    },err => { console.error(err); })
}


// Check attribute lable
check_attribute_label(data){
    let temp_label ='Select'
    if(data && data.length != 0){
      data.map(res =>{
        if(res.is_pre_selected == 1){
            temp_label = res.option_value
        }
    })
    }
    return temp_label;
}

notify_me(item){
  if(this.cust_name){
    let data =  {"attributeId": '',"sender_name": this.cust_name,"sender_email": this.cust_email,"product":item.name}
    let datas = { data:  JSON.stringify(data)}
    this.insert_stockavail_notification(datas).subscribe(res =>{
      if(res.message.user_name){
        this.alert('success');
      }
    })
  } else{
    
  }
}


check_stocks(item){
  return (item.inventory_method == 'Track Inventory' && (item.stock == 0  || item.stock <= item.count) && item.count == 0)
}

check_attribute_stock(no_stock,item){
  return (item.count == 0 && no_stock) ? true : false
}

check_disabled(item){
    return (item.inventory_method == 'Track Inventory' && (item.stock == 0  || item.stock <= item.count)) || item.disable_add_to_cart_button == 1
}

checkcart(id) {
  var cnt = 0;
  this.cart_items.find(res => {  if (res.product == id && res.is_free_item != 1) {  cnt += res.quantity } })
  return cnt;
}


checkWishCart(id){
  var cnt = 0;
  this.wishcart_items.find(res => {  if (res.product == id) { cnt += res.quantity } })
  return cnt;
}

getShippingMethods() {
  var data={   domain: this.domain }
  this.get_shipping_method(data).subscribe((r: any) => {
    if(r.message != undefined)
        this.shipping_methods = r.message;
        // console.log("Shipping methods",this.shipping_methods)
        // r.message.map(res =>{
        //   if(res.shipping_method_name == 'Delivery'){

        //   }
        // })
        // console.log("shipping methods",this.shipping_methods)
    }, error => { console.log(JSON.stringify(error.json()));
  });
}


getSlot(delivery_method){
  // console.log(delivery_method)
  let slots = [];
   
  //  console.log("Slot gettting",this.delivery_slots)
   var data = {  shipping_method : delivery_method , customer_id : localStorage.customerRefId }
   this.get_cart_delivery_slots(data).subscribe(res => {
    //  console.log("delivery slots",res);
         if(res.message){
             res.message.map( value =>{
              slots.push(value);
           })
         }
   })

   this.delivery_slots = slots;
 }



 select_shipping_method(shippingmethod_id){



    this.shipping_methods.map(res =>{
      if(res.name == shippingmethod_id){
        this.shipping_settings.selected_shipping_method_name = res.shipping_method_name
      }
    })

    this.shipping_settings.selected_shipping_method = shippingmethod_id;

      // var ship_data = {
      //   shipping_addr : this.selected_address.name,
      //   subtotal : this.cart_data.cart.total,
      //   shipping_method : this.shipping_settings.selected_shipping_method,
      //   cartId : this.cart_data.cart.name,
      //   business : this.businessId
      // }
      this.getSlot(this.shipping_settings.selected_shipping_method)
      let d ={
        ship_addr :  this.selected_address.name,
        business :  this.businessId,
        order_total :  this.cart_data.cart.total,
        discount :  this.discounts.discount
      }

      if(this.shipping_settings.selected_shipping_method == 'SH00001' ){
        this.calculate_restaurant_ship_charge(d).subscribe(res =>{
          // console.log("calculating....",res);
          if(res.message.status == 'success'){
            this.shipping_settings.shipping_charges = res.message.charge
            this.shipping_settings.shipping_distance = res.message.distance
            this.shipping_settings.shipping_tax = res.message.tax  
          } 
            this.shipping_selected.next('shipping_charges')
            this.get_order_discounts();
        })
      } else {
        this.shipping_settings.shipping_charges = 0
        this.shipping_selected.next('shipping_charges')
        this.get_order_discounts();
      }

      

    //   this.calculate_vendor_based_shipping_charges(ship_data).subscribe((data:any) => {
    //     // console.log("shipping charges..",data)
    //     if(data.message){
    //         this.shipping_settings.shipping_charges = data.message
    //         this.shipping_selected.next('shipping_charges')
    //         // console.log(this.shipping_settings)
    //     } else{
    //        this.shipping_selected.next('shipping_charges')
    //     }
    //  })

 }


 
 get_order_discounts(){
  // this.discount_products = [];
  // console.log(this.shipping_settings.shipping_charges)
    var data = {
      subtotal : this.cart_data.cart.total,
      customer_id: localStorage.customerRefId,
      shipping_method : this.shipping_settings.selected_shipping_method,
      // payment_method: this.selected_payment_method,
      shipping_charges: this.shipping_settings.shipping_charges
    }


  this.get_order_discount(data).subscribe((res:any) => {
    // console.log(" discounts",res)

    if( res && res.message && res.message.status  == 'success'){
        this.discounts.discount_amount = res.message.discount_amount;
        // this.discounts.discount = res.message.discount;

        res.message.discount_response.shipping_discount == 1  ? this.shipping_settings.shipping_charges = res.message.discount_response.shipping_charges : null
        res.message.discount_response.shipping_discount == 1  ? this.shipping_settings.shipping_label = res.message.discount_response.shipping_label : null
        this.discounts.discount = res.message.discount_response.shipping_discount_id

        this.calculate.next('calculate');
        // !res.message.discount ?  localStorage.discount__code = res.message.discount : null
        // res.message.discount_amount ? this.discount_price = res.message.discount_amount : null

        // if(res.message.discount_response.product_discount){
        //   if(res.message.discount_response.products_list){
        //     res.message.discount_response.products_list.map(value =>{
        //       if(value.discount){
        //         this.discount_price +=  value.discount
        //       }
        //       this.discount_products.push(value)
        //     })
        //   }

        //   if(res.message.discount_response.shipping_discount){
        //     this.shipping_charges = res.message.discount_response.shipping_charges
        //   }
        // }

        // (!this.shipping_settings.included_tax) ?  this.tax_amount = res.message.tax : this.tax_amount = 0;
        // (this.coupon_code && this.coupon_code) ? this.promo() : null
        // this.calculate_total_amount();
        
    } else {
      // this.discount_price = 0;
    }
  }, error => {
     console.log(JSON.stringify(error.json()));
  });

}

promo() {
  if (this.coupon_discounts.coupon_code != '') {
    var data = {
      coupon_code: this.coupon_discounts.coupon_code,
      subtotal: this.cart_data.cart.total,
      customer_id: localStorage.customerRefId,
      shipping_method : this.shipping_settings.selected_shipping_method,
      // payment_method : this.selected_payment_method
    }

    this.validate_coupon(data).subscribe(data => {
        // console.log("promo",data);

        if (data.message.status == 'success') {
          this.coupon_discounts.discount_amount = data.message.discount_amount
          this.coupon_discounts.discount = data.message.discount
          localStorage.coupon__code = data.message.discount

          if(data.message.discount_response.free_item == 1){
            data.message.discount_response.products_list.map( res =>{
              res.is_free_item = 1
            })
            this.coupon_discounts.discount_products = data.message.discount_response.products_list
            this.cart_items = [...this.cart_items,...data.message.discount_response.products_list]
            // console.log(this.cart_items)
          }
          // console.log("before promo discount",this.discount_price)
          // this.db.discounts.discount_amount += data.message.discount_amount;
          // console.log("after promo discount",this.discount_price)
          // (!this.shipping_settings.included_tax) ?  this.tax_amount = data.message.tax : this.tax_amount = 0;
          // this.calculate_total_amount();
        } else {
            this.coupon_discounts.discount_amount = 0
            this.coupon_discounts.discount = data.message.discount
            this.alert(data.message.message)
        }
        this.calculate.next('calculate');
      }, error => {
        this.alert('Invalid Coupon Code')
        this.coupon_discounts.coupon_code = '';
        console.log(JSON.stringify(error.json()));
      });
    } else {
       this.alert('Please Enter Coupon Code')
    }
  }


  cancel_promo(){
    this.coupon_discounts  = { discount_amount : 0, discount : '',coupon_code : '',discount_products : [] }
    this.cart_data.cart ? this.cart_items = this.cart_data.cart.items : null
    this.calculate.next('calculate');
  }



// Get route base urls
get_routes(){
    this.get_url().subscribe( res => {
      this.baseUrl = res.url;
      this.baseResource = res.url + '/api/resource/'
      this.baseMethod = res.url + '/api/method/'
      this.domain = res.domain;
      this.route = res.router_link;
      // this.route = "gogrocery-demo-home-page";
    },err => { console.log(JSON.stringify(err))});
}


async alert(data){
    const alert = await this.alertCtrl.create({
        header : 'Alert',
        subHeader :data,
        buttons :['Ok']
    })
    await alert.present();
    await alert.onWillDismiss().then(res =>{
      // console.log(res)
    })
}

  get_website_settings(){
    this.get_all_website_settings().subscribe(res =>{
      this.website_settings = res.message
      // console.log(res);
    })
  }
// async openloginModal() {
//   if (this.modal_popup && !this.cust_name){
//     this.modal_popup = false;
//     const modal = await this.modalCtrl.create({
//       component: RegisterPage,
//       cssClass: 'auth-popup',
//       componentProps: {
//         type: 'login'
//       }
//     })
//     this.opened_login_modal = true;
//     await modal.present();
//     await modal.onWillDismiss().then( res =>{
//       this.modal_popup = true;
//       });
//   }
// }
  // async openQuickView(product_info){
  //   let temp_path = '';
  //   const modal = await this.modalCtrl.create({
  //     component: QuickviewPage,
  //     cssClass:  'quick-view-popup',
  //     componentProps: {  product_info : product_info }
  //   })
  //   temp_path = location.pathname;
  //   this.location.replaceState('/product-detail/'+product_info.name);
  //   this.modal = true;
  //   await modal.present();
  //   let data = await modal.onWillDismiss();
  //   if(data){
  //     this.modal = false;
  //     this.location.replaceState(temp_path);
  //   }
  // }

dismiss() {
  if (this.ismobile) {
    if(this.opened_login_modal){
      this.modalCtrl.dismiss()
    }else{
      this.ismobile ? this.router.navigate(['/tabs/home']) : this.router.navigate(['/']);
    }
  } else {
    this.modalCtrl.dismiss();
  }
}



async openLogin() {
  if (this.modal_popup && !this.cust_name) {
      // this.modal_popup = false;
      this.opened_login_modal = true;
      this.modal_popup = false;
      this.modal = true;
      const modal = await this.modalCtrl.create({
        component: RegisterPage,
        cssClass:'auth-popup',
        componentProps: {
          type: 'login',
          modal: true
        }
      })

      await modal.present();

      await modal.onWillDismiss().then( res =>{
        this.modal_popup = true;
        this.opened_login_modal = false;
        this.get_address();
        this.get_cart_item();
        if(this.ismobile && this.cust_name){
          this.getShippingMethods();
        }
        this.modal = false;
        
      });
  }
}

// async openQuickView(value){
//   const modal = await  this.modalCtrl.create({
//     component: QuickviewPage,
//     cssClass: 'quick-view-popup',
//     componentProps: { product_info :value }
//   })
//   this.modal = true;
//   modal.present();
//   let data = modal.onWillDismiss();
//   data ? this.modal = false : null
// } 




async add_address() {
  let modal = await this.modalCtrl.create({
    component: AddAddressPage,
    cssClass: 'address-popup',
    componentProps: {
        address_type: 'Add',
        modal: true
        }
    });
  await modal.present();
  this.modal = true;
  let { data } = await modal.onWillDismiss();

  if(data && data.status == 'success') {
    // this.db.get_address();
    this.modal = false;
  }
}

async open_address_popup(){
  if(this.cust_name && this.modal_popup){
    let modal = await this.modalCtrl.create({
      component: AddressListPage,
      cssClass: 'address-popup',
      componentProps: {
        title: 'Add',
        modal : true
      }
    });
    this.modal_popup = false
    this.modal = true
    await modal.present();
   await modal.onWillDismiss().then(res =>{
      this.modal = false
      this.modal_popup = true
    });
  }
}


// for subscription
async buy_now() {
  if (localStorage.customerRefId) {
      if(this.subscription_plan_info_status == 'Active') {
          this.alert('User is already subscribed !')
      }else{
          this.open_subscriber();
      }
  } else if (!localStorage.customerRefId) {
      const modal = await this.modalCtrl.create({
        component: RegisterPage,
        cssClass: 'auth-popup',
        componentProps: {
          type: 'login',
        },
      });
      this.modal = true;
      await modal.present();
      let data = modal.onWillDismiss();
    if (data && localStorage.customerRefId) {
       this.open_subscriber();
    }
  }
}


async open_subscriber() {
  const modal = await this.modalCtrl.create({
    component: SubscribePlanPage,
  });

  await modal.present();
  let { data } = await modal.onWillDismiss();
  if (data && data.status == 'success') {
    this.subscribe_emit.next("modal closed")
    this.modal = false;
  }
}


async logout() {
    const alert = await this.alertCtrl.create({
      header: 'Logout',
      message: 'Are you sure do you Want to logout..?',
      buttons: [
        {
          text: 'Cancel',
          handler: () => { this.alertCtrl.dismiss() }
        },
        {
          text: 'Ok',
          handler: () => {
            this.cust_name = undefined;
            this.cust_email = undefined;
            localStorage.Address = undefined
            this.delivery_slots = [];
            this.selected_slot = [];
            this.shipping_methods  = [];
            this.shipping_settings  = {};
            this.billing_address = {};
            this.selected_address = {};
            this.discounts  = { discount_amount : 0, discount : ''};
            this.coupon_discounts  = { discount_amount : 0, discount : '',coupon_code : '' }
            this.cart_data = {}
            this.cart_items = [];
            this.cart_count = 0;
            this.wish_count = 0;
            this.total_amount = 0;
            this.shipping_settings = { shipping_charges :0 }
            localStorage.clear();
            // this.router.navigateByUrl('/');
            this.ismobile ? this.router.navigate(['/tabs/home']) : this.router.navigate(['/']);
          }
        }
      ]
   })
    await alert.present();
  }


back(){
    if (this.modal) {
      this.modal = false;
      this.modalCtrl.dismiss();
    } else {
      this.navCtrl.back();
    }
}

reload(){
  window.location.reload();
}

get windowRef(){
  return window;
}


plusCount(count) {
  count = count + 1;
  return count;
}


removeCount(count) {
  if (count != 0) {
    count = count - 1;
    return count;
  } else {
    return 0;
  }
}


cartChange(data) {
  if (data == 'mycart') {
    this.wishcart = false;
    this.mycart = true;
  } else if (data == 'wishcart') {
    this.wishcart = true;
    this.mycart = false;
  }
}



scrolled(data){
  // console.log(data);
// data.detail.scrollTop < data.detail.startY
  if(data.detail.scrollTop < 150){
    // console.log(data.detail)
    this.scroll_event.desktop_header = true;
  }else {
    this.scroll_event.desktop_header  = false
  }

  if(data.detail.scrollTop < 200){
      this.scroll_event.mobile_header = false;
  }else{
      this.scroll_event.mobile_header = true;
  }


  if(data.detail.scrollTop < 61){
      this.scroll_event.no_search_icon = false;
  }else{
      this.scroll_event.no_search_icon = true;
  }
}


  async share(){
    if(this.ismobile){
      await Share.share({
        title: 'See cool stuff',
        text: 'Really awesome thing you need to see right here',
        url: 'https://meatton.com/'+location.pathname,
        dialogTitle: 'Share with buddies',
      });
    } else{
        const modal = await  this.modalCtrl.create({
          component:SocialSharePage,
          cssClass:'sharing-popup'
        })
          await modal.present();
    }
  }


  async get_address() {
    this.customer_Info({user: localStorage.customerRefId}).subscribe(data => {
        if(data.message && data.message[0].address && data.message[0].address.length != 0){
          this.address_list = data.message[0].address;
          this.choose_default_address();
        } else {
          this.selected_address = undefined
          localStorage.Address = undefined
        }
        // console.log("Address list",  this.address_list);
    }, error => { console.log(JSON.stringify(error.json())); });
  }


  choose_default_address(){
    
        this.selected_address = this.address_list[0];
        localStorage.Address = JSON.stringify(this.selected_address);
     
      this.address_list.map(res =>{
        if(res.is_default == 1 ){
          this.selected_address = res;
          localStorage.Address = JSON.stringify(this.selected_address);
        }
      })

      this.choose_address(this.selected_address);
  }


  choose_address(address){
    this.selected_address = address
    this.billing_address = address
    this.shipping_settings.selected_shipping_method = undefined
    this.shipping_settings.selected_shipping_method_name = undefined
    // this.check_area();
    this.location_info.latitude = Number(address.latitude )
    this.location_info.longitude =  Number(address.longitude )
    this.zipcode_availbility(address.zipcode,'empty')
  }


  search_location(event){
      if(event.detail.value != '') {
        
        // this.get_google_locations({query:event.detail.value}).subscribe(res =>{
        //   console.log(res)  ;
        //   this.location_sugesstions.push(res)
        // })
        let  GoogleAutocomplete = new google.maps.places.AutocompleteService();
        //  location: {lat:'13.0260134',lng:'80.1498735'} , radius:500 
        GoogleAutocomplete.getPlacePredictions({ input: event.detail.value, componentRestrictions: {country: 'IN',} , types: ['establishment']},
        (predictions, status) => {
          this.ngzone.run(() => {
              this.location_sugesstions = []
            if(predictions && predictions.length != 0){
              predictions.forEach((prediction) => {
                if(prediction){
                  this.location_sugesstions.push(prediction)
                }
              });
            }
          });
        });
      } else{
          this.location_sugesstions = []
      }
  }


  // get_place_details(d){
    // let  GoogleAutocomplete = new google.maps.places.PlacesService()
    // GoogleAutocomplete.getDetails( d.referance,
    //   (data,status)=>{
    //       console.log(data)
    //   })
  // }

  choose_multistore_location(data){
      this.businessId = data.business
      this.city = data.core_city
      this.location = data.core_city
      this.router.navigateByUrl('/tabs/home')
  }


  validate_location(data){
    // this.locationpopup = false
    // console.log(data)
    // this.location_sugesstions = []
    // this.get_place_details(data);
          // console.log(status)
      // console.log(data)
      // if (status == google.maps.GeocoderStatus.OK) {
      //   var latitude = data[0].geometry.location.lat();
      //   var longitude = data[0].geometry.location.lng();
      //   // alert(latitude);
      // }
      this.location_info.latitude = data.geometry.location.lat();
      this.location_info.longitude = data.geometry.location.lng();
      this.located.next('located');
      // console.log("address",this..location_info.latitude);
    const geocoder =  new google.maps.Geocoder();
    geocoder.geocode({ placeId: data.place_id }, (data,status) => {
      if (status == google.maps.GeocoderStatus.OK) {
          data[0].address_components.map(res =>{
            // console.log(data[0])
              if(res.types[0] == "postal_code"){
                this.location_info.zipcode = res.long_name;
                this.zipcode_availbility(this.location_info.zipcode,data);   
              }
              res.types[0] == "country" ? this.location_info.country = res.long_name : null
              res.types[0] == "administrative_area_level_1" ? this.location_info.state = res.long_name : null
              res.types[0] == "locality" ?   (this.city,this.location_info.city = res.long_name) : null
              this.location = data[0].formatted_address; 
              this.location_info.address = data[0].formatted_address.split(this.location_info.city)[0].trim();
              // console.log(this.location_info)
          })
      }
    })
  }


  decode(latlng) {
    const geocoder =  new google.maps.Geocoder();
    // const infowindow = new google.maps.InfoWindow();
   geocoder.geocode({ location: latlng }, (data,status) => {
    if (status == google.maps.GeocoderStatus.OK) {
      data[0].address_components.map(res =>{
        this.location_info.latitude = data[0].geometry.location.lat();
        this.location_info.longitude = data[0].geometry.location.lng();
        // console.log(this.location_info)
          if(res.types[0] == "postal_code"){
            this.location_info.zipcode = res.long_name;
            this.zipcode_availbility(this.location_info.zipcode,data);   
          }
          res.types[0] == "country" ? this.location_info.country = res.long_name : null
          res.types[0] == "administrative_area_level_1" ? this.location_info.state = res.long_name : null
          res.types[0] == "locality" ?   (this.city,this.location_info.city = res.long_name) : null
          this.location = data[0].formatted_address; 
          this.location_info.address = data[0].formatted_address.split(this.location_info.city)[0].trim();
          // console.log(this.location_info)
      })
    }
    })
  }
  
  // for(var i = 0; i < results[0].address_components.length; i++) {
  //   if(results[0].address_components[i].types[0] == "postal_code")
  //     this.delivery.zipcode = results[0].address_components[i].long_name;
  //   else if(results[0].address_components[i].types[0] == "country")
  //     this.delivery.country = results[0].address_components[i].long_name
  //   else if(results[0].address_components[i].types[0] == "administrative_area_level_1")              
  //     this.delivery.state = results[0].address_components[i].long_name
  //   else if(results[0].address_components[i].types[0] == "locality")
  //     this.delivery.city = results[0].address_components[i].long_name;
  //   else if(results[0].address_components[i].types[0] == "political")
  //     this.delivery.state = results[0].address_components[i].long_name;
  //   this.delivery.address=results[0].formatted_address.split(this.delivery.city)[0].trim();

  // }

  getPlaceAutocomplete(input) {
    const autocomplete = new google.maps.places.Autocomplete(input,
        {
          componentRestrictions: { country: 'IN' },
          types: ['establishment']  // 'establishment' / 'address' / 'geocode'
        });
        google.maps.event.addListener(autocomplete, 'place_changed', (res) => {
          // console.log(res)
            const place = autocomplete.getPlace();
            // console.log(place);
            this.validate_location(place)
        });

    //  let  GoogleAutocomplete = new google.maps.places.AutocompleteService();
    //     GoogleAutocomplete.getPlacePredictions({ input:input, componentRestrictions: {country: 'IN'}  },
    //     (predictions, status) => {
    //       this.ngzone.run(() => {
    //         predictions.forEach((prediction) => {
    //            console.log(prediction)
    //          //this.autocompleteItems.push(prediction);
    //         });
    //       });
    //   });  
   }



  async current_locate() {
    this.geo.getCurrentPosition().then((resp) => {
        this.location_info.latitude = resp.coords.latitude //13.0381896 //
        this.location_info.longitude = resp.coords.longitude// 80.1565461 //
        this.located.next('located');
        // console.log(resp)
        let locations = new google.maps.LatLng(resp.coords.latitude, resp.coords.longitude);
        this.decode(locations)
     }).catch((error) => {
       //  this.db.alert_animate.next('Please enable location..,');
        //  console.log('Error getting location', error);
        //  this.ismobile ? this.router.navigateByUrl('/location') : null
        //  ( !this.location && !this.ismobile) ? ( ):null
    });
  }




  

  search_places(data):Observable<any>{
    // ?query='+data+'&key=AIzaSyD6HganWCiI-AxUOkYuCy1E1dDKCQeWEJM&libraries
    // let doc = { query : data }
    let d = {
      place_id :'ChIJN1t_tDeuEmsRUsoyG83frY4',
      key : 'AIzaSyD6HganWCiI-AxUOkYuCy1E1dDKCQeWEJM'
    }
    // https://maps.googleapis.com/maps/api/place/details/json?fields=name%2Crating%2Cformatted_phone_numbe&place_id=ChIJN1t_tDeuEmsRUsoyG83frY4&key=AIzaSyD6HganWCiI-AxUOkYuCy1E1dDKCQeWEJM
    let url = 'https://maps.googleapis.com/maps/api/place/details/js/json?place_id=ChIJN1t_tDeuEmsRUsoyG83frY4&key=AIzaSyD6HganWCiI-AxUOkYuCy1E1dDKCQeWEJM';
    return  this.get(url);
  }





  store_info(){
    this.city = localStorage.city
    this.location_info.city = localStorage.city
    this.location = localStorage.setlocation
    // localStorage.zipcode
    // this.top_zipcode_availbility()
    // this.check_area()
    // localStorage.zipcode = this.zipcode;
  }


  
//   top_zipcode_availbility(data){
//     // console.log(data);

//     this.pincode_availability({zipcode: data.zipcode}).subscribe(res =>{
//       if(res.message.is_available == 1){
//         this.location_error = false;
//         this.available_location = false;
//         this.locationpopup = false

//         this.ismobile ? this.router.navigateByUrl('/tabs/home') : this.locationpopup = false;
//       } else {
//         this.location_error = true;
//         this.available_location = true;
//       }
//     })
// }

  get_areas(){
    this.get_area_list().subscribe(res =>{
      this.area_list = res.message
    })
  }


  // check_available_zipcode(data){
  //   let pin_code_list = [];
  //   this.area_list.map(res =>{
  //     pin_code_list.push(res.zipcode);
  //   })
  //   if(data != '' &&  data.length == 6 && pin_code_list.includes(data)){
  //     this.available_location = false;
  //   } else if(data != '' && data.length == 6 && !pin_code_list.includes(data)){
  //     this.available_location = true;
  //     return 'Shipping is not available in your location'
  //   }
  // }


  check(){
    return this.location_error
  }


  // check_area(){
  //   if(this.selected_address && this.selected_address.zipcode){
  //     let data = this.selected_address.zipcode

  //     let pin_code_list = [];
  //     this.area_list.map(res =>{
  //       pin_code_list.push(res.zipcode);
  //     })
  //     if(data != '' && data.length == 6 && pin_code_list.includes(data)){
  //       // temp = 
  //       // console.log('Shipping is not available in your location',data)
  //       // available_location
  //       this.available_location = false;
  //     } else {
  //       this.available_location = true;
  //     }
  //   }
  //   // return temp;
  // }



    zipcode_availbility(zip_code,address){

      zip_code == 'local' ? zip_code = address.zipcode : ''

      let d = {"business" : this.businessId, lat: this.location_info.latitude,lng:this.location_info.longitude}
      this.check_distance_by_latlng(d).subscribe(res =>{
          // console.log(res);
          if(res.message.status == 'success'){
            this.location_error = false;
            this.available_location = false;
            if(address != 'empty'){
              address.zipcode ? this.store_local_location(address,zip_code) :  this.store_map_location(address,zip_code)
              this.ismobile ? this.router.navigateByUrl('/tabs/home') : (this.modal_stacks.includes('location-modal') ?  this.modalCtrl.dismiss() : null);
            } 
            this.modal_stacks.includes('location-modal') ?  this.modalCtrl.dismiss() : null
          } else {
            this.location_error = res.message.message;
            this.available_location = true;
          }
          setTimeout(() => {   window.dispatchEvent(new Event('resize')); }, 100);
      })

      // let data = {"business" : this.businessId, "zipcode" : zip_code}
      // this.check_distance_by_zipcode(data).subscribe(res =>{
      //   if(res.message.status != 'failed'){
      //       this.location_error = false;
      //       this.available_location = false;
      //       if(address != 'empty'){
      //         address.zipcode ? this.store_local_location(address,zip_code) :  this.store_map_location(address,zip_code)
      //         this.ismobile ? this.router.navigateByUrl('/tabs/home') : (this.modal_stacks.includes('location-modal') ?  this.modalCtrl.dismiss() : null);
      //       } 
            
      //       this.modal_stacks.includes('location-modal') ?  this.modalCtrl.dismiss() : null      
      //   } else {
      //       this.location_error = true;
      //       this.available_location = true;
      //   }
      //   setTimeout(() => {   window.dispatchEvent(new Event('resize')); }, 100);
      // })
      
      // this.pincode_availability({zipcode: zip_code}).subscribe(res =>{
      //   if(res.message.is_available == 1){
      //     this.location_error = false;
      //     this.available_location = false;
      //     if(address != 'empty'){
      //       address.zipcode ? this.store_local_location(address,zip_code) :  this.store_map_location(address,zip_code)
      //       this.ismobile ? this.router.navigateByUrl('/tabs/home') : (this.modal_stacks.includes('location-modal') ?  this.modalCtrl.dismiss() : null);
      //     } 
      //     this.modal_stacks.includes('location-modal') ?  this.modalCtrl.dismiss() : null
      //   } else {
      //     this.location_error = true;
      //    res.message.message
      //    this.available_location = true;
      //   }
      //   setTimeout(() => {   window.dispatchEvent(new Event('resize')); }, 100);
      // })
    }

    store_map_location(address,zip_code){
      localStorage.address = address[0]
      // console.log(address[0])
      localStorage.setlocation = address[0].formatted_address
      localStorage.zipcode = zip_code;
    }
  
    store_local_location(address,zip_code){
        this.location_info.city = address.city
        this.city = address.city
        this.location = address.area +","+address.city+"-"+address.zipcode
        localStorage.city = address.city
        localStorage.setlocation =  address.area +","+address.city+"-"+address.zipcode
        localStorage.zipcode = zip_code;
    }
    // this.pincode_availability({zipcode: zip_code}).subscribe(res =>{
    //     if(res.message.is_available == 1){
    //       this.location_error = false;
    //       this.locationpopup = false;
    //       this.available_location = false;

    //       localStorage.address = address[0]
    //       localStorage.setlocation = address[0].formatted_address
    //       localStorage.zipcode = zip_code;

    //       location.pathname == '/location' ? this.router.navigateByUrl('/home') : this.locationpopup = false
    //       // console.log("It's available",res.message);
    //   } else {
    //       this.location_error = true;
    //       // console.log("Not available");
    //       this.available_location = true;
    //   }
    // })
  // }



  get_razorpay_settings() {
    this.get_razorpaysetting().subscribe((r: any) => {
      	if(r.message != undefined){
          // console.log("Razor pay",r.message)
            this.razorpay_settings = r.message;
    	}
      }, error => {    console.log(JSON.stringify(error.json())); });
  }



  async deposit(){
    const alert = await this.alertCtrl.create({
      header: 'Deposit Wallet',
      // message:'Amount',
      inputs: [
          {
              name: 'amount',
              type: 'number',
              placeholder: 'Enter amount'
          }
      ],
      buttons: [
          {
              text: 'Cancel',
              role:'cancel',
              handler: () => {   // console.log('Confirm Cancel');
          }
          }, 
          {
              text: 'Ok',
              handler: (alertData) => {this.load_razorpay(alertData.amount,"Insert Wallet Amount")}
          }
      ]
    })
    await alert.present();
  }


  async load_razorpay(amount,description) {
    var options = {
      "key": this.razorpay_settings.api_key,
      "amount": (amount * 100).toString(),
      "currency": "INR",
      "name": "Meatton",
      "description": "Payment for" + description,
      "image": (this.razorpay_settings.site_logo ? this.product_img(this.razorpay_settings.site_logo) : null),
      "prefill": {
          "name": localStorage.customerName,
          "email": localStorage.vendorId ? localStorage.email : localStorage.email,
          "contact": localStorage.Customerphone
      },
      // "notes": {
      //     "Order id": order.name
      // },
      "theme": {
          "color": "#000000"
      },
      "modal": {
        "backdropclose": false,
        "ondismiss": () => {  setTimeout(() => { }, 2000); }
      }
    };


    try {
      let data = (await Checkout.open(options));
              data['response']['amount'] = amount
              data['response']['description'] = description
            
            if(description == 'Insert Wallet Amount'){
                this.wallet_payment_capture(amount,data['response']['razorpay_payment_id'])
            } else if(description == 'Order Repay'){
                this.razorpay_payment.next(data['response']);
            } else if(this.check_which(description)[0] == 'Subscription' || this.check_which(description)[0] == 'Subscription Repay'){
                this.subscripiton_payment_capture(data['response']['razorpay_payment_id'],this.check_which(description)[1])
            } else {
                this.order_payment_capture(data['response']['razorpay_payment_id'],description);
            }

            // this.payment_capture(data['response']['razorpay_payment_id'],amount);
            //  this.db.alert(data['response']['razorpay_payment_id']);
      } catch (error) {
        if(description == 'Insert Wallet Amount'){
          
        } else if(description == 'Order Repay'){
        
        } else if(this.check_which(description)[0] == 'Subscription' || this.check_which(description)[0] == 'Subscription Repay') {
            
        } else{
          this.order_payment_capture(undefined,description);
        }
           this.alert('Payment Failed'); //Doesn't appear at all
      }

    }

    check_which(data){
     let d = data.split(',')
     return d
    } 

    // 
    wallet_payment_capture(amount,transaction_id){
      let data = {
        customer_id:localStorage.customerRefId,
        payment_method:'Razor Pay',
        transaction_id: transaction_id,
        amount: Number(amount),
        order_from:'Android Mobile App'
      }
      this.insert_wallet_order(data).subscribe(res =>{
        if(res.message.status == 'success'){
          this.get_wallet_detail(1);
        }
        // console.log(res);
      })
  }


  async order_payment_capture(id,order_id) {
    // console.log("Payment id",id,order_id)
    // let check_payment = this.paymentMethod.find(obj=>obj.name == this.selected_payment_method);
    // if (check_payment.payment_method == 'Razor Pay' ||  check_payment.payment_type == 'Online Payment') {
      let loader = await this.loadingCtrl.create({ message: 'Please Wait...'});
      await loader.present();
      if(id){
        var updatedate = {  'order_id': order_id,  'transaction_id': id  }
        this.update_order_status(updatedate).subscribe(r => {
            // console.log("Order Status Updated..",r);
            this.success(order_id);
        }, error => { console.log(JSON.stringify(error.json())); });
      }  else {
        
        this.success(order_id);
      }

        // this.success();
      // }
    // }
}

loader_dismiss(){
  setTimeout(() => {  this.loadingCtrl.dismiss();; })
}

subscripiton_payment_capture(id,order_id){
    let data ={
        'order_id': order_id,
        'transaction_id': id,
        capture:1
      }
      this.update_order_status(data).subscribe(res=>{
          if(res.message.status == true){
            this.alert('Subscription is sucessfully completed.')
            this.modalCtrl.dismiss({'status': 'success'});
          }
      })
}


success(order_id){
  // if(id == '' && order_id == ''){
    // this.db.delivery_slots = [];
    // this.db.selected_slot= [];
    // this.db.shipping_methods  = [];
    // this.db.shipping_settings  = {};
    this.loader_dismiss();
    this.delivery_slots = [];
    this.selected_slot = [];
    this.shipping_methods  = [];
    this.shipping_settings  = {};
    this.cart_data = {}
    this.cart_items = [];
    this.cart_count = 0;
    this.total_amount = 0;
    this.wish_count = 0;
    this.get_cart_item();
    this.router.navigate(['/thankyou',order_id], { replaceUrl: true })
    // this.ismobile ? this.router.navigateByUrl('/my-orders') : this.router.navigate(['/thankyou/'+order_id], { replaceUrl: true });;

  // }
}

  get_wallet_detail(page_no){
    let data = {  list_type: 'app_pay', vendor: localStorage.customerRefId, page_no: page_no,  page_len: (this.ismobile? 10: 20)  }
    this.get_wallet_details(data).subscribe(res =>{
      if(res.message.length != 0){
        // this.payment_bank.wallet = true;
        if(res.message[0].transactions.length != 0){
          this.website_settings.no_more_wallet_history = false;
          page_no == 1 ? ( this.wallet_detail = res.message[0] ) :  (this.wallet_detail = [...this.wallet_detail,...res.message[0]])
        } else {
          this.website_settings.no_more_wallet_history = true;
        }
       
        this.website_settings.wallet_no_history = false
      } else {
        this.website_settings.wallet_no_history = true
          // this.no_info  = true
        //  this.payment_bank.wallet = false;
      }   
    })
  }

     // Call this function when your app starts
     OneSignalInit() {
       if(this.oneSignalId ){
          OneSignal.setAppId(this.oneSignalId)
          OneSignal.setNotificationOpenedHandler((data) => {
            if (data.notification.body.indexOf('Your order') != -1 || data.notification.body.indexOf('your order') != -1) {
              // this.nav.push(OrderPage,{name:data.notification.additionalData['add_data'], type:'order'})
            }
            else{
                const routUrl = data.notification.additionalData['url']
                this.get_all_route({'route':routUrl}).subscribe((res)=>{
                    console.log(res,"response",data.notification.additionalData['url'])
                    if(res.message.type == 'Product Category'){
                        this.router.navigateByUrl('/cl/'+routUrl)
                    }else if(res.message.type == 'Product'){
                        this.router.navigateByUrl('/pr/'+routUrl)
                    }
                })
            }
            // console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
          });
          OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
            console.log('User accepted notifications: ' + accepted);
          });
          OneSignal.getDeviceState((res) =>{
              console.log(res)
              localStorage.player_id = res.userId;
              localStorage.customerRefId ? this.update_onsignal_id().subscribe(res =>{ console.log("one signal id updated..",res)  }) : null
          })
        }
    }

  // decode_uri(data){
  //   console.log(data);
  //   console.log(decodeURIComponent(data));
  //   return decodeURIComponent(data);
  // }

  get_business_info(){
    let data = { "name" : this.businessId }
    this.get_business_detail(data).subscribe(res=>{
        this.business_info = res.message[0]
        // this.business_info.business_addressres = this.business_info[0].business_address.split(',');
        
    })
  }

  get(endpoint: string) {
    if (localStorage.api_key != undefined) {
      this.httpHeaders = new HttpHeaders({ 'mode':'no-cors','Authorization': 'token ' + localStorage.api_key + ":" + localStorage.api_secret });
    }
    return this.http.get(endpoint, this.httpOptions);
  }

  postmethod(endpoint: string,data) {
      if(localStorage.api_key != undefined){
        this.httpHeaders = new HttpHeaders({ 'Authorization': 'token '+localStorage.api_key+":"+localStorage.api_secret });
      }
      if(localStorage.customerRefId && localStorage.api_key) {
        this.httpOptions = ({ headers: this.httpHeaders });
      } else {
        this.httpOptions = {};
      }
    return this.http.post(endpoint, data, this.httpOptions);
  }


  log_rocket(): Observable<any>{
    let endpoint = "https://meatton-tridotstech-default-rtdb.firebaseio.com/logrocket.json"; //go1grocery
    return this.http.get(endpoint);
  }

  get_url(): Observable<any>{
    let endpoint = "https://go-grocery-25a29.firebaseio.com/coreurl.json"; //go1grocery
    // let endpoint = "https://go-meat-default-rtdb.firebaseio.com/core_url.json"; //go1meat
    return this.http.get(endpoint);
  }

  getLogin(accountInfo: any): Observable<any> {
    return this.postmethod(this.baseMethod+'login', accountInfo);
  }

  register(data): Observable<any>{
    data = {  data :JSON.stringify(data)  }
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.insert_customers';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  insert_edit_address(data):Observable<any>{
    var datas = { data:JSON.stringify(data) }
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.insert_customers';
    return this.postmethod(this.baseMethod + endpoint, datas);
  }

  sasslogin(data):Observable<any>{
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.login_customer';
    return this.postmethod(this.baseMethod+endpoint, data);
  }

  check_guest_customer(data):Observable<any>{
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.check_guest_customer';
    return this.postmethod(this.baseMethod+endpoint, data);
  }

  get_social_login(data):Observable<any>{
    var datas={
      data:JSON.stringify(data),
      get_user_token:0
    }
    let endpoint="ecommerce_business_store.ecommerce_business_store.mobileapi.social_login_customer"
    return this.postmethod(this.baseMethod + endpoint, datas)
  }

  // get_number(phone):Observable<any> {
  //   var data = {
  //     phone: phone,
  //     guest_id:(localStorage.guestRefId && localStorage.guestRefId != '' ) ? localStorage.guestRefId : null,
  //     domain:this.domain
  //   }
  //   let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.get_customer_info';
  //   return this.postmethod(this.baseMethod + endpoint, data);
  // }

  // customer_info(id,guest_id):Observable<any> {
  //   var data = {
  //     user: id,
  //     guest_id:(localStorage.guestRefId && localStorage.guestRefId != '' ) ? localStorage.guestRefId : null,
  //     domain:this.domain
  //   }
  //   let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.get_customer_info';
  //   return this.postmethod(this.baseMethod + endpoint, data);
  // }

  // loginInfo(email):Observable<any> {
  //     let data = {
  //       email: email,
  //       guest_id: (localStorage.guestRefId && localStorage.guestRefId != '' ) ? localStorage.guestRefId : null
  //     }
  //     let endpoint = "ecommerce_business_store.ecommerce_business_store.api.get_customer_info"
  //     return this.postmethod(this.baseMethod + endpoint, data);
  // }

  // user: email,
  customer_Info(info):Observable<any> {
    let guest =  (localStorage.guestRefId && localStorage.guestRefId != '' ) ? localStorage.guestRefId : null
    info.guest_id = guest
    // var data = { guest_id: }

    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.get_customer_info';
    return this.postmethod(this.baseMethod + endpoint, info);
  }

  get_mobile_homepage(data):Observable<any>{
    // let endpoint = "ecommerce_business_store.ecommerce_business_store.mobileapi.get_pagebuilder_data"
    let endpoint= 'ecommerce_business_store.ecommerce_business_store.mobileapi.get_mobile_home_page';
    return this.postmethod(this.baseMethod+endpoint,data)
  }

  insert_cart_items(data):Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.insert_cartItems';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  movetocart(data):Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.movetocart';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  movealltocart(data):Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.movealltocart';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_cart_items(data):Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.get_cartItems';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  delete_cart_items(data):Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.deleteCartItems';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  clear_cartitem(data):Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.clear_cartitem';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  update_cart_items(data):Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.update_cartitem';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_area_list():Observable<any> {
    let data = ''
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.get_all_area_list';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_google_locations(data):Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.get_google_locations';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  pincode_availability(data):Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.check_pincode_availability';
    return this.postmethod(this.baseMethod + endpoint, data);
  }
  
  check_distance_by_zipcode(data):Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.check_distance_by_zipcode';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  check_distance_by_latlng(data):Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.check_distance_by_latlng';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_parent_categories(data):Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.get_parent_categories';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_expected_delivery(data):Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.check_user_location_delivery'
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  sub_product_category(data):Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.get_category_products';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_category_page_filters(data):Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.get_category_page_filters'
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_razorpaysetting():Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.razor_pay_settings';
    return this.get(this.baseMethod + endpoint)
  }

  get_payment_method(data):Observable<any>{
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.get_payment_method';
    return this.postmethod(this.baseMethod + endpoint,data)
  }

  get_shipping_method(data):Observable<any>{
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.get_shipping_method';
    return this.postmethod(this.baseMethod + endpoint, data);
  }


  update_user(data,Id):Observable<any> {
    var datas={
      doc:JSON.stringify(data)
    }
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.update_doc';
    return this.postmethod(this.baseMethod + endpoint, datas);
  }



  validate_coupon(data):Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.validate_coupon'
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_order_discount(data):Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.get_order_discount';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  calculate_vendor_based_shipping_charges(data):Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.calculate_vendor_based_shipping_charges';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  calculate_restaurant_ship_charge(data):Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.calculate_restaurant_ship_charge';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_generalsetting(data) :Observable<any>{
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.get_general_settings';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_shipping_settings(data):Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.get_settings_list';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_cart_delivery_slots(data):Observable<any>{
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.get_cart_delivery_slots';
    return this.postmethod(this.baseMethod + endpoint, data);
  }


  check_menu_availability(data):Observable<any>{
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.check_cartitems_stock'
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  insertOrder(data):Observable<any> {
    var datas = {  data: JSON.stringify(data) }
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.insert_order';
    return this.postmethod(this.baseMethod + endpoint, datas);
  }

  get_order_details(data):Observable<any> {
    let endpoint="ecommerce_business_store.ecommerce_business_store.api.get_order_details"
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  reorder(data){
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.reoder_checking';
    return this.postmethod(this.baseMethod + endpoint, data);
  }


  order_lists(data):Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.order_list';
    return this.postmethod(this.baseMethod + endpoint, data);
  }


  insert_address(data):Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.insert_address';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  update_address(data):Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.update_address';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  delete_address(data):Observable<any>{
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.delete_address';
    return this.postmethod(this.baseMethod + endpoint, data);
  }



  get_states_lists(country):Observable<any>{
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.get_country_states?country=' + country;
    return this.get(this.baseMethod + endpoint);
  }

  get_country_list():Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.get_country_list';
    return this.get(this.baseMethod + endpoint);
  }

  get_product_details(data):Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.get_product_details'
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  insert_contact_form(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.contactform';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_page_details(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.get_page_details';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  insert_review(data): Observable<any>  {
    var datas={ data:JSON.stringify(data) }
   let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.insert_review';
   return this.postmethod(this.baseMethod + endpoint,datas);
  }

  insert_questionaproduct(data): Observable<any>  {
    var datas={ data:JSON.stringify(data) }
   let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.insert_questionaproduct';
   return this.postmethod(this.baseMethod + endpoint,datas);
  }

  get_product_reviews_list(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.doctype.product.product.get_product_reviews_list';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  insert_email_subscription(data): Observable<any>{
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.insert_email_subscription';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  search_products(data): Observable<any>{
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.get_search_products';
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  validate_stock(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api._validate_stock';
    return this.postmethod(this.baseMethod + endpoint, data);
  }
  
  validate_attributes_stock(data): Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.validate_attributes_stock';
    return this.postmethod(this.baseMethod + endpoint, data);
  }
  
  validate_product_cart_qty(data):Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.validate_product_cart_qty';
    return this.postmethod(this.baseMethod + endpoint, data);
  }
  
  check_cartitems_stock_mob(data):Observable<any>{
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.check_cartitems_stock_mob';
    return this.postmethod(this.baseMethod + endpoint, data);
  }


  forget_password(data):Observable<any>{
    let endpoint = 'frappe.core.doctype.user.user.reset_password';
    return this.postmethod(this.baseMethod + endpoint,data);
  }

  wallet_details(data):Observable<any>{
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.wallet_details'
    return this.postmethod(this.baseMethod + endpoint,data);
  }

  get_wallet_details(data):Observable<any>{
    let endpoint = 'ecommerce_business_store.accounts.api.get_wallet_details'
    return this.postmethod(this.baseMethod + endpoint,data);
  }

  insert_wallet_order(data):Observable<any>{
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.insert_wallet_order'
    return this.postmethod(this.baseMethod + endpoint,data);
  }

  get_customer_points(data):Observable<any>{
    let endpoint = 'loyalty.loyalty.api.get_customer_points'
    return this.postmethod(this.baseMethod + endpoint,data);
  }

  get_point_history(data):Observable<any>{
    let endpoint = 'loyalty.loyalty.api.get_point_history'
    return this.postmethod(this.baseMethod + endpoint,data);
  }

  checkout_redeem_points(data):Observable<any>{
    let endpoint = 'loyalty.loyalty.api.checkout_redeem_points'
    return this.postmethod(this.baseMethod + endpoint,data);
  }

  update_password(data):Observable<any>{
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.update_password'
    return this.postmethod(this.baseMethod + endpoint,data);
  }

  get_all_blog_list(data):Observable<any>{
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.get_blog_list';
    return this.postmethod(this.baseMethod + endpoint,data);
  }
//


  get_blog_categories():Observable<any>{
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.get_blog_categories';
    return this.http.get(this.baseMethod + endpoint);
  }

  get_blog_details(data):Observable<any>{
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.get_blog_details';
    return this.postmethod(this.baseMethod + endpoint,data);
  }

  get_insert_blog_comments(data):Observable<any>{
    data = {  data :JSON.stringify(data)  }
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.insert_blog_comments';
    return this.postmethod(this.baseMethod + endpoint,data);
  }

  get_business_detail(data):Observable<any>{
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.get_business_detail';
    return this.postmethod(this.baseMethod + endpoint,data);
  }


  insert_web_subscription(data):Observable<any>{
    let endpoint = 'subscription.subscription.api.insert_web_subscription';
    return this.postmethod(this.baseMethod + endpoint,data);
  }

  renew_web_subscription(data):Observable<any>{
    let endpoint = 'subscription.subscription.api.renew_web_subscription';
    return this.postmethod(this.baseMethod + endpoint,data);
  }


  update_order_status(data):Observable<any> {
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.update_order_status'
    return this.postmethod(this.baseMethod + endpoint, data);
  }

  get_subscribed_plans(data):Observable<any>{
    let endpoint = 'subscription.subscription.api.get_customer_plan_based_subscritpions';
    return this.postmethod(this.baseMethod + endpoint,data);
  }

  list_subscription_plans():Observable<any>{
    let endpoint = 'subscription.subscription.api.list_subscription_plans';
    return this.http.get(this.baseMethod + endpoint);
  }

  insert_stockavail_notification(data):Observable<any>{
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.insert_stockavail_notification';
    return this.postmethod(this.baseMethod + endpoint,data);
  }

  get_all_website_settings():Observable<any>{
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.get_all_website_settings';
    return this.get(this.baseMethod + endpoint);
  }

  watching():Observable<any>{
    return  this.fire.collection('Orders').valueChanges();
  }

  firebase_watchig():Observable<any>{
    return this.af.object('web_version').valueChanges();
  }

  // firebase_watchig():Observable<any>{
  //   return this.af.object('/side_menu/menu').valueChanges();
  // }

  update_onsignal_id():Observable<any> {
    // console.log("fired..")
    var playerId = { player_id: localStorage.player_id, }
    let endpoint = 'Customers/' + localStorage.customerRefId;
    return this.http.put(this.baseResource + endpoint, playerId,this.httpOptions);
  }

  signInWithGoogle() {
    const provider = new auth.GoogleAuthProvider();
    const scopes = ['profile', 'email'];
    return this.socialSignIn(provider.providerId, scopes);
  }

  socialSignIn(providerName: string, scopes?: Array<string>): Promise<any> {
    const provider = new auth.OAuthProvider(providerName);
    // add any permission scope you need
    if (scopes) {
      scopes.forEach(scope => {
        provider.addScope(scope);
      });
    }
    if (this.platform.is('desktop') || this.ismobile) {
      return this.fireauth.signInWithPopup(provider);
    } else {
      // web but not desktop, for example mobile PWA
      return this.fireauth.signInWithRedirect(provider);
    }
  }

  check_birthdayclub(data):Observable<any>{
    let endpoint = 'Customers?filters=[["email","=","'+data+'"]]';
    return this.get(this.baseResource + endpoint );
  }

  inert_birthdayclub(data):Observable<any>{
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.insert_doc';
    return this.postmethod(this.baseMethod + endpoint,data);
  }

  get_customer_dashboard(data):Observable<any>{
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.api.get_customer_dashboard';
    return this.postmethod(this.baseMethod + endpoint,data);
  }

  get_all_route(data):Observable<any>{
    let endpoint = 'ecommerce_business_store.ecommerce_business_store.mobileapi.get_all_route';
    return this.postmethod(this.baseMethod + endpoint,data);
  }

    tabs_items =[ 
    {
        title:'Home',
        route: '/tabs/home',
        icon : '/assets/icon/home.svg',
        tab: 'home',
        enable: 1,
    },
    {
      title:'Category',
      route: '/tabs/category',
      icon : '/assets/icon/category.svg',
      tab: 'category',
      enable: 1
    },
    {
      title:'Wishlist',
      route: '/tabs/wishlist',
      icon : '/assets/icon/heart.svg',
      tab: 'wishlist',
      enable: 1
    },
    // {
    //   title:'Wallet',
    //   route: '/tabs/wallet',
    //   icon : '/assets/icon/wallet.svg',
    //   tab: 'wallet',
    //   enable: 1
    // },
    {
      title:'Account',
      route: '/tabs/my-profile',
      icon : '/assets/icon/user-sidemenu.svg',
      tab: 'my-profile',
      enable: 1
    },
  ]

  menu =[
    {
      title: 'Home',
      route: '/tabs/home',
      icon: '/assets/icon/home.svg',
      enable: 1
    },
    {
      title: 'Categories',
      route: '/tabs/category',
      icon: '/assets/icon/category.svg',
      enable: 1
    },
    {
      title: 'Wishlist',
      route: '/tabs/wishlist',
      icon: '/assets/icon/heart.svg',
      enable: 1
    },
    {
      title: 'MyCart',
      route: '/yourcart',
      icon: '/assets/icon/mycart.svg',
      enable: 1
    },
    {
      title: 'Orders',
      route: '/my-orders',
      icon: '/assets/icon/cart.svg',
      enable: 1
    },
    {
      title: 'Profile',
      route: '/tabs/my-profile',
      icon: '/assets/icon/user-sidemenu.svg',
      enable: 1
    },
    {
      title: 'Wallet',
      route: '/tabs/wallet',
      icon: '/assets/icon/wallet.svg',
      enable: 0
      // (this.db.website_settings && this.db.website_settings.enable_wallet == 1) ? 1 : 0
    },
    {
      title: 'Reward Points',
      route: '/reward-points',
      icon: '/assets/icon/Reward.svg',
      enable: 0
      // (this.db.website_settings && this.db.website_settings.enable_loyalty == 1) ? 1 : 0
    },
    {
      title: 'Contact Us',
      route: '/contact-us',
      icon: '/assets/icon/contact-us.svg',
      enable: 1
    },
    {
      title: 'Terms & conditions',
      route:'/terms-condition',
      icon: '/assets/icon/terms.svg',
      enable: 1
    },
    {
      title:'Privacy Policy',
      route:'/privacy-policy',
      icon: '/assets/icon/privacy-policy.svg',
      enable: 1
    },
    {
      title:'Cancellation Policy',
      route:'/cancellation-policy',
      icon: '/assets/icon/cancellation-policy.svg',
      enable: 1
    }
  ]


}
