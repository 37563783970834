import {
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-wish-list',
  templateUrl: './wish-list.component.html',
  styleUrls: ['./wish-list.component.scss'],
})
export class WishListComponent implements OnInit {
  // wishlist = [];
  // isWishlist = false;
  // url;
  @Input() no_footer;
  @Input() load;
  constructor(public db: DbService) {
    // this.url = this.db.baseUrl;
   }

  ngOnInit() {
    if(this.load) {
      this.get_cart_item();
    } else{
      this.get_cart_item();
    }
  }

  doRefresh(event) {
    // console.log('Begin async operation');
    this.get_cart_item();
    setTimeout(() => {
      // console.log('Async operation has ended');
      event.target.complete();
    }, 700);
  }

  get_cart_item(){
    if (localStorage.customerRefId != undefined) {
      this.db.get_cart_item();
      // var ddatas={
      //   customer:localStorage.customerRefId,
      //   user:""
      // }
      // this.db.get_cart_items(ddatas).subscribe(data => {
      //    this.db.store_cart_datas(data);
      //   // if(data.message.cart && data.message.cart.items.length != 0){
      //   //   this.db.cart_count = data.message.cart.items.length;
      //   //   this.db.cart_items = data.message.cart.items;
      //   // }

      //   // if( data.message.wishlist && data.message.wishlist.items.length != 0 && data.message.wishlist != null){
      //   //   this.isWishlist= false;
      //   //   this.wishlist = data.message.wishlist.items;
      //   // }else{
      //   //   this.isWishlist = true;
      //   //   this.wishlist = [];
      //   //   this.db.wish_count = 0
      //   // }
      // }, error => {  console.log(JSON.stringify(error.json()));
      //   //  this.isWishlist= true;
      // });
      // }else{
      //   // this.isWishlist= true;
      }
  }


  removewish(list, que){
      var cartid = '';

      this.db.wishcart_items.map(res =>{
        if(res.name == list.name){
          cartid = res.name
        }
      })

      var datas={  name:cartid,   customer:localStorage.customerRefId, user:"" }
      this.db.delete_cart_items(datas).subscribe(data => {
          this.get_cart_item();
        }, error => {console.log(JSON.stringify(error.json()));});
  }


  addtowish(list, que){
    var datas={
      item_code:list.product,
      qty:que,
      qty_type:"",
      rate:list.price,
      cartType:"Shopping Cart",
      customer:localStorage.customerRefId,
      attribute:'',
      attribute_id:''
    }
     this.db.insert_cart_items(datas).subscribe(data => {
        this.get_cart_item()
        this.removewish(list, que)
      }, error => { console.log(JSON.stringify(error.json()));});
  }


  movetocart(list) {
    let data = {
      user: localStorage.customerUser_id,
      name: list.name,
      customer: localStorage.customerRefId
    }
    this.db.movetocart(data).subscribe(res => {
      if(res.message && res.message.error) {
        this.db.alert('Sorry! no stock available');
        return;
      }
      this.get_cart_item();
    }, error => { console.log(JSON.stringify(error.json()));})
  }


  moveallto_Cart(){
    let data = {   user: localStorage.customerUser_id, customer: localStorage.customerRefId }
    this.db.movealltocart(data).subscribe(res => {

      if(res.message.status == "failed") {
        this.db.alert(res.message.message);
      } else if(res.message.status == "success"){
        this.get_cart_item();
        this.db.wishcart = false;
        this.db.mycart = true;
      }
    
    }, error => { console.log(JSON.stringify(error.json()));})
  }

}
