import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  NgZone,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
// import { AngularFirestore } from '@angular/fire/firestore';
import {
  NavigationEnd,
  Router,
} from '@angular/router';

import * as LogRocket from 'logrocket';
import { environment } from 'src/environments/environment';

// import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
// import {
//   SwPush,
//   SwUpdate,
// } from '@angular/service-worker';
// import { concat, interval,} from 'rxjs';
// import { first } from 'rxjs/operators';
// import { SwUpdate } from '@angular/service-worker/';
import { StatusBar } from '@capacitor/status-bar';
// declare var google : google;
// import { google } from 'google-maps';
// import { google } from 'google-maps';
// import { Geolocation } from '@ionic-native/geolocation/ngx';
// import {
//   NativeGeocoder,
//   NativeGeocoderOptions,
//   NativeGeocoderResult,
// } from '@ionic-native/native-geocoder/ngx';
import {
  MenuController,
  ModalController,
  Platform,
  PopoverController,
} from '@ionic/angular';

import { DbService } from './services/db.service';

// declare var google : google;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent implements OnInit {
  path;
  tabs = false;
  modal_popup = true;
  added_animate = false;
  alert_animate = false;
  add_animatings: any = [];
  alert_animatings: any = [];


  // versioning = 15
  // isBrowser;
  // public oneSignal:OneSignal
  constructor(public db:DbService,private router:Router,private platform:Platform,private modalCtrl:ModalController,private popupCtrl:PopoverController,private menuCtrl:MenuController,private zone: NgZone,@Inject(PLATFORM_ID) private platformId) {
    // private update: SwUpdate,private appRef: ApplicationRef,private swPush: SwPush,public fire: AngularFirestore
    // console.log("meatton version",this.versioning)
    this.updateClient();
    // this.checkUpdate();
    // this.initializeApp();
     this.db.current_locate();
     this.db.isBrowser = isPlatformBrowser(platformId);
    //  console.log("latest build ..." ,this.versioning)
  }

  // forceReload() {
  //   console.log('refresh');
  //   const form = document.createElement('form');
  //   form.method = "POST";
  //   form.action = "https://meatton-tridotstech.web.app";
  //   // form.action = location.href;
  //   document.body.appendChild(form);
  //   form.submit();
  //   // this.refreshForm.nativeElement.submit();
  // }


//   initializeApp() {
//     App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
//         this.zone.run(() => {
//             // Example url: https://beerswift.app/tabs/tab2
//             // slug = /tabs/tab2
//             const slug = event.url.split(".app").pop();
//             if (slug) {
//                 this.router.navigateByUrl(slug);
//             }
//             // If no match, do nothing - let regular routing
//             // logic take over
//         });
//     });
// }

  
  ngOnInit() {
    this.db.wishcart = false;
    this.db.mycart = false;
    this.db.ismobile = this.db.checkmobile();
    this.db.get_website_settings()
    this.db.get_areas();
    this.get_product_category();
    
    // this.check_enabled();
    if(this.db.ismobile){
      this.platform.ready().then(res =>{
        // console.log(res)
        if((this.db.ismobile || res == 'ios' || res == 'ipad' || res == 'iphone' || res == 'mobile' || res == 'tablet') && res != 'dom'){
          this.db.OneSignalInit();
          StatusBar.setBackgroundColor({color:'#000000'});
        } 
     })
    }    

    // this.db.android_log_rocket()
      this.db.log_rocket().subscribe(res => {
        // console.log(res)
        res.enabled && environment.production ? LogRocket.init(res.id) : null
      }, error => { console.log(JSON.stringify(error.json()));  });


    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.path = event.url;
        this.db.path = event.url;
        // if(this.path == '/'){
        //   this.db.ismobile ? this.router.navigateByUrl('/tabs/home') : null
        // }
        if (this.path == '/' || this.path =='/home' || this.path == '/category' || this.path == '/wishlist' || this.path == '/my-profile' ) {
          this.tabs = true;
        } else {
          this.tabs = false;
        }    
      }
    });

    if(localStorage.setlocation && localStorage.setlocation != undefined){
      this.db.store_info()
    }
    
    if (localStorage.customerRefId && localStorage.CustomerName != '') {
      this.db.cust_email = localStorage.CustomerId;
      this.db.cust_name = localStorage.CustomerName;
      // if(localStorage.Address && localStorage.Address != undefined ){
      //    // this.db.selected_address = JSON.parse(localStorage.Address)
      //    // this.db.check_area()
      // } else {
      //    this.db.selected_address = {};
      // }
    }

    
    if(!this.db.cust_name && localStorage.guestRefId && localStorage.guestRefId != ''){
      this.db.check_guest_customer({customer_id: localStorage.guestRefId}).subscribe(res => {
          localStorage.customerRefId  =  res.message.customer_id
      })
        // localStorage.customerRefId 
        // localStorage.guestRefId 
    }


    this.db.added_animate.subscribe(res =>{
      this.added_animate = true;
      this.add_animatings.push(res);
      setTimeout( () => {  this.add_animatings.shift();},1500);
    })
    
    // this.db.alert_animate.subscribe(res =>{
      // this.alert_animate = true;
      // !this.db.modal ? this.alert_animatings.push(res) : null
      // setTimeout( () => {  this.alert_animatings.shift();}, this.db.ismobile? 700: 1500 );
    // })
    // this.db.search_places('').subscribe(res =>{
    //   console.log(res)
    // })

    // GoogleAuth.initialize({
    //   client_id: 'CLIENT_ID.apps.googleusercontent.com',
    //   scopes: ['profile', 'email'],
    //   grantOfflineAccess: true,
    // });

    // this.pushSubscription();
    // this.swPush.messages.subscribe((message) => console.log(message));
    // this.swPush.notificationClicks.subscribe(({ action, notification }) => {
    //   window.open(notification.data.url);
    // });
    this.db.get_business_info();
    this.db.get_razorpay_settings();	
  }


  // pushSubscription() {
  //   if (!this.swPush.isEnabled) {
  //     console.log('Notification is not enabled');
  //     return;
  //   }

  //   this.swPush.requestSubscription({ serverPublicKey: this.publicKey, }) .then((sub) => {
  //       // Make a post call to serve
  //       console.log(JSON.stringify(sub));
  //     }).catch((err) => console.log(err));
  // }


  // clearCacheStorage(): Promise<Promise<boolean>[][]> {
  //   return caches.keys().then(cacheKeys =>
  //     Promise.all(
  //       cacheKeys.map(cacheKey => {
  //         const ngswRegex = /^(ngsw).*/;
  //         if (ngswRegex.test(cacheKey)) {
  //           return caches  .open(cacheKey)
  //             .then(cache => cache.keys().then(requests => requests.map(req => cache.delete(req))));
  //         }
  //       })
  //     )
  //   );
  // }

  
  
  updateClient() {
    // this.fire.collection('Orders').snapshotChanges().subscribe(res =>{
    //   console.log(res);
    //   // location.reload()
    //   // this.update.activateUpdate().then(() => );
    // })\

    // console.log('swUpdate  isEnabled: ' + this.update.isEnabled);
    // if(this.update.isEnabled){
    //      if (localStorage.getItem('refreshed') === null) {
    //         localStorage['refreshed'] = true;
    //         this.update.activateUpdate().then(() =>  window.location.href = window.location.href);
    //     } else {
    //         localStorage.removeItem('refreshed');
    //     }
    // }

    // this.db.firebase_watchig().subscribe(res =>{
    //   console.log(res);
    //   res.version != this.versioning ? ( location.reload()  , this.clearCacheStorage().then(() => {  window.location.href = window.location.href })) : null
    //   // this.get_order_list();
    // })


        // this.update.available.subscribe(event => {
    //   const changelog = event.available.appData['changelog'];
    //   const message = changelog + " Click to refresh.";
    //   if (confirm(message)) {
    //       window.location.reload();
    //   }
    // });

    // if (!this.update.isEnabled) {
    //   console.log('sW is Not Enabled');
    //   return;
    // }

    // this.update.available.subscribe(event => {
    //   console.log('current version is', event.current);
    //   console.log('available version is', event.available);
    // });

    // this.update.activated.subscribe(event => {
    //   console.log('old version was', event.previous);
    //   console.log('new version is', event.current);
    // });
   



    // if(this.update.isEnabled) {
    //   if (confirm('update available for the app please conform')) {
    //     // this.update.activateUpdate().then( () =>{ window.cache.clear() });
    //   }
    // }

    // this.update.available.subscribe((event) => {
    //   console.log(`current`, event.current, `available `, event.available);
    //   if (confirm('update available for the app please conform')) {
    //     this.update.activateUpdate().then(() => location.reload());
    //   }
    // });




    // const updatesAvailable = this.update.versionUpdates.pipe(filter((evt): evt is VersionReadyEvent => 
    // evt.type === 'VERSION_READY'), map(evt => ({type: 'UPDATE_AVAILABLE',current: evt.currentVersion,available: evt.latestVersion,})));

    // this.update.activated.subscribe((event) => {
    //   console.log(`current`, event.previous, `available `, event.current);
    // });

    // const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
    // const everySixHours$ = interval(6);
    // const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);
    // everySixHoursOnceAppIsStable$.subscribe(() => this.checkForUpdates());
  }

  // public checkForUpdates(): void {
  //   this.update.available.subscribe(event => {
  //     console.log('updating to new version..');
  //     this.update.activateUpdate().then(() =>{ location.reload() }); 
  //   });
  // }



  // checkUpdate() {
  //   // this.appRef.isStable.subscribe((isStable) => {
  //   //   if (isStable) {
  //       // const timeInterval = interval(8 * 60 * 60 * 1000);

  //       // timeInterval.subscribe(() => {
  //       //   this.update.checkForUpdate().then(() => console.log('checked'));
  //       //   console.log('update checked');
  //       // });

  //       const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
  //       const everySixHours$ = interval(6 * 60 * 60 * 1000);
  //       const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);
  //       everySixHoursOnceAppIsStable$.subscribe(() => this.update.checkForUpdate());
  //   //   }
  //   // });
  // }



  // check_enabled(){
  //   var settingdata = { doctype: 'Wallet Settings', domain:this.db.domain  }

  //   this.db.get_shipping_settings(settingdata).subscribe((r:any) => {
  //      if(r.message != undefined && r.message.enable_customer_wallet == 1){
  //         this.db.payment_bank.wallet_disabled = true;
  //        console.log("Wallet settigs",r.message);
  //      }
  //    }, error => {    console.log(JSON.stringify(error.json())); });
  // }


  scrolled(event){
    if(event.scrollTop){
    }
  }


  @HostListener('window:resize', ['$event'])
  private func(){
     this.db.ismobile = this.db.checkmobile();
  }
  

  ionViewWillEnter() {
  }  



  get_product_category() {
    let data = {  domain : this.db.domain ,show_count :1}
    this.db.get_parent_categories(data).subscribe( res => {
        this.db.category = res.message;
    })
  }


  cart_popup(data) {
    if (data == 'mycart') {
      this.db.wishcart = false;
      this.db.mycart = true;
    } else if(data == 'wishcart') {
      this.db.wishcart = true;
      this.db.mycart = false;
    }
    this.menuCtrl.open();
  }


    menuclose() {    
      this.db.wishcart = false;
      this.db.mycart = false;
    }




}


