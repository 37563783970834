import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { DbService } from 'src/app/services/db.service';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.page.html',
  styleUrls: ['./add-address.page.scss'],
})
export class AddAddressPage implements OnInit {

  @Input() address_type: any;
  @Input() address_info: any = {};
  // submitted = false;
  // country_list: any = [];
  // states_list: any = [];
  // address_form: FormGroup;
  constructor(public db:DbService,public formBuilder: FormBuilder, public modalCtrl: ModalController) { 
  	// this.get_country_list();
  }

  ngOnInit() {
  	// if(this.address_type == 'Add') {
  	// 	this.address_info.first_name = localStorage.Customerfirst_name || '';
    //   this.address_info.last_name = localStorage.Customerlast_name || '';
    //   this.address_info.address = '';
  	// 	this.address_info.city = '';
  	// 	this.address_info.zipcode = '';
  	}

  //   this.address_form = this.formBuilder.group({ 
  //     first_name: new FormControl((this.address_info.first_name || ''), Validators.required),
  //     last_name: new FormControl((this.address_info.last_name || ''), Validators.required),
  //     address: new FormControl((this.address_info.address || ''), Validators.required),
  //     city: new FormControl((this.address_info.city || ''), Validators.required),
  //     state: new FormControl((this.address_info.state || ''), Validators.required),
  //     country: new FormControl((this.address_info.country || ''), Validators.required),
  //     zipcode: new FormControl((this.address_info.zipcode || ''), Validators.required)
  //   });
  //   if(this.country_list.length == 0 && this.address_info.country) {
  //     this.country_list = [{'name': this.address_info.country}];
  //     this.address_form.value.country = this.address_info.country;
  //   }
  //   if(this.states_list.length == 0 && this.address_info.state) {
  //     this.states_list = [{'name': this.address_info.state}];
  //     this.address_form.value.state = this.address_info.state;
  //   } 
  // }

  // backbtn(){
  //   this.modalCtrl.dismiss();
  // }

  // get_country_list() {
  // 	this.DBservice.get_country_list()
  // 	.subscribe((r: any) => {
  // 		this.country_list = r.message;
  // 		if(this.address_info.country) {
  //       this.address_form.value.country = this.address_info.country;
  //     }
  // 		this.get_state_list();
  // 	}, error => {
  // 		console.log(error)
  // 	})
  // }

  // get_state_list() {
  // 	this.DBservice.get_states_lists(this.address_info.country)
  // 	.subscribe((r: any) => {
  // 		this.states_list = r.message;
  // 		if(this.address_info.state) {
  //       this.address_form.value.state = this.address_info.state;
  //     }
  // 	}, error => {
  // 		console.log(error)
  // 	})
  // }

  // value_changed(evt) {
  // 	if(evt.detail.value) {
  // 		this.address_info.country = evt.detail.value;
  //     this.get_state_list();
  // 	}
  // }


  // insert_address() {
  //   this.address_info = this.address_form.value;
  //   this.submitted = true;
  //   this.address_info.customer = localStorage.customerRefId;
  //   this.address_info.addr1 = this.address_info.address;
  //   this.address_info.pincode = this.address_info.zipcode;
  //   if(this.address_form.valid) {
  //     this.DBservice.insert_address({'data': JSON.stringify(this.address_info)})
  //     .subscribe((r: any) => {
  //       this.modalCtrl.dismiss({'status': 'success'});
  //     }, error => {
  //       console.log(error);
  //     })
  //   }
  // }

  // dismiss_modal() {
  //   this.modalCtrl.dismiss({'status': 'closed'});
  // }

  
  // edit_address() {

  //   console.log(this.address_form.value)
  //   console.log(this.address_info);
  //   console.log(this.address_form.value['first_name'])

  //   this.submitted = true;
  //   this.address_info.customer = localStorage.customerRefId;
    
  //   this.address_info.first_name = this.address_form.value['first_name'];
  //   this.address_info.addr1 = this.address_form.value.address;
  //   this.address_info.pincode = this.address_form.value.zipcode;
  //   this.address_info.city = this.address_form.value.city;
  //   this.address_info.state = this.address_form.value.state;
  //   this.address_info.country = this.address_form.value.country;
  //   if(this.address_form.valid) {
  //     this.DBservice.update_address({'data': JSON.stringify(this.address_info)})
  //     .subscribe((r: any) => {
  //       this.modalCtrl.dismiss({'status': 'success'});
  //     }, error => {
  //       console.log(error);
  //     })
  //   }
  // }

}
