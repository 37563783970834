import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import {
  RouteReuseStrategy,
  RouterModule,
} from '@angular/router';

import { StarRatingModule } from 'ionic5-star-rating';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { environment } from 'src/environments/environment';

import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
// import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { SmsRetriever } from '@awesome-cordova-plugins/sms-retriever/ngx';
import {
  IonicModule,
  IonicRouteStrategy,
} from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderModule } from './components/header/header.module';
import { MobileMenuModule } from './components/mobile-menu/mobile-menu.module';
import { WishListModule } from './components/wish-list/wish-list.module';
import { YourCartModule } from './components/your-cart/your-cart.module';

// import { GooglePlus } from '@ionic-native/core';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule.withServerTransition({ appId: 'serverApp' }), HeaderModule,
    ReactiveFormsModule, WishListModule,
    YourCartModule,
    MobileMenuModule, FormsModule,
    IonicModule.forRoot({mode:'md'}),
    AppRoutingModule, RouterModule,
    HttpClientModule,
    LazyLoadImageModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    StarRatingModule,
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerWhenStable:30000' }),
  ],

  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },Geolocation,GooglePlus,SmsRetriever],
  bootstrap: [AppComponent],
})
export class AppModule {}
