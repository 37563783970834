import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { LazyLoadImageModule } from 'ng-lazyload-image';

import { IonicModule } from '@ionic/angular';

import { YourCartComponent } from './your-cart.component';

@NgModule({
  declarations: [YourCartComponent],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    LazyLoadImageModule
  ],
  exports: [YourCartComponent]
})
export class YourCartModule { }
