import {
  Component,
  OnInit,
} from '@angular/core';

import { DbService } from 'src/app/services/db.service';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-subscribe-plan',
  templateUrl: './subscribe-plan.page.html',
  styleUrls: ['./subscribe-plan.page.scss'],
})
export class SubscribePlanPage implements OnInit {
  selected_plan: any = {};
  subscription_details;
  plan_details = [];
  constructor(private db:DbService,public modalCtrl:ModalController) { }

  
  ngOnInit() {
    this.get_plan();
    this.db.get_razorpay_settings();
  }


  select(event) {
    this.selected_plan = event.detail.value;
  }


  get_plan() {
    this.db.ismobile = this.db.checkmobile();
    this.db.list_subscription_plans().subscribe(res => {
      this.plan_details = res.message;
    })
  }


subscriptions() {
  var today : any  = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' +  dd;
      let data = {
        "party": localStorage.customerRefId,
        "party_name": localStorage.Customerfirst_name,
        "start": today,
        "subscription_plan" : this.selected_plan.name,
        "paymentmethod" : "PAY-M00002"
      }
      this.db.insert_web_subscription(data).subscribe(res => {
        if (res.message.status == "Failed") {
          var b = JSON.parse(res._server_messages);
          var b1 = JSON.parse(b);
          this.db.alert(b1.message);
        }else if (res.message.status == true) {
            this.subscription_details = res.message;
            this.db.load_razorpay(this.subscription_details.order.total_amount,'Subscription,'+this.subscription_details.order.name);
        }
        console.log(res.message.status)
      })
}


closemodal(){
  this.modalCtrl.dismiss();
}


}
