// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    // apiKey: "AIzaSyAU1APK5KlOoFvIi0QQ5V1bYX-auU3b8BU",
    // authDomain: "ecommerce-core-app.firebaseapp.com",
    // projectId: "ecommerce-core-app",
    // storageBucket: "ecommerce-core-app.appspot.com",
    // messagingSenderId: "1035519028295",
    // appId: "1:1035519028295:web:e3af54f2ca4639af5f1562",
    // measurementId: "G-S3JWRE4WHG"

      measurementId: "G-H13R6HHZ59",
      apiKey: "AIzaSyACalUwmKhSH4Bb7xMR0WbwHMkmVQRFAsc",
      authDomain: "meatton-tridotstech.firebaseapp.com",
      databaseURL: "https://meatton-tridotstech-default-rtdb.firebaseio.com",
      projectId: "meatton-tridotstech",
      storageBucket: "meatton-tridotstech.appspot.com",
      messagingSenderId: "775637215007",
      appId: "1:775637215007:web:bb70202bb01ff3dffa322a"

      // apiKey: "AIzaSyA5iPIBZAz0px1F0lmyG75WlX2s2oCqWq4",
      // authDomain: "meatton-admin.firebaseapp.com",
      // databaseURL: "https://meatton-admin-default-rtdb.firebaseio.com",
      // projectId: "meatton-admin",
      // storageBucket: "meatton-admin.appspot.com",
      // messagingSenderId: "335441004362",
      // appId: "1:335441004362:web:1a38e4948dfb2b05b7e3b3",
      // measurementId: "G-7G3KR36ERX"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
