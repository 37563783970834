import {
  Component,
  OnInit,
} from '@angular/core';

import { DbService } from 'src/app/services/db.service';
import { Router,NavigationEnd  } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-social-share',
  templateUrl: './social-share.page.html',
  styleUrls: ['./social-share.page.scss'],
})
export class SocialSharePage implements OnInit {
  share_url;
  // 
  name = 'Get Current Url Route Demo';
  currentRoute: string;
  // 
  constructor(public modalCtrl:ModalController,public db:DbService) {
      // this.share_url ="https://meatton-tridotstech.web.app"+location.pathname
      this.share_url = window.location.href
   }

  ngOnInit() {
    // console.log(this.router.url,"--");
    // console.log(window.location.href);
    // console.log("get path",this.getHostname())
  }

  dismiss(){
    this.modalCtrl.dismiss()
  }

  

}
