import { ViewportScroller } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import { LocationPage } from 'src/app/pages/location/location.page';
import { DbService } from 'src/app/services/db.service';

import {
  AlertController,
  MenuController,
  ModalController,
  NavController,
  Platform,
} from '@ionic/angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() back_route;
  @Input() home;
  @Input() no_search_icon;
  @Input() no_home;
  @Input() no_cart;
  @Input() cat_products;
  @Input() title;
  @Input() search_btn;
  @Input() menu_btn;
  @Input() menu;
  @Input() desktop;
  // @Input() modal;

  @Input() location_popups;
  @Output() openModal = new EventEmitter();
  @Output() cart_pop = new EventEmitter();
  // @Output() fetch_location = new EventEmitter();

  drop_down2 = false; //My account drop down
  // drop_down3 = false; //Location drio down
  search_down = false;
  product_drop_down = true;
  child_drop_down = true;
  search_area = false;
  // ismobile;
  keyword;
  // change_location = false;
  childs; //Second categories
  sub_childs;
  search_productss =[];
  all_category = false;
  category_left_arrow = false;
  location_dropdown = false;

  @ViewChild('dropdown1', { read: ElementRef }) dropdown1: ElementRef;
  @ViewChild('dropdown2', { read :ElementRef}) dropdown2 : ElementRef;
  @ViewChild('location_container', { read :ElementRef}) location_container : ElementRef;
  @ViewChild('mainproduct', { read :ElementRef}) mainproduct : ElementRef;
  @ViewChild('child_productdown', { read :ElementRef}) child_productdown : ElementRef;
  @ViewChild('all_categories',{ read :ElementRef}) all_categories : ElementRef;
  @ViewChild('category',{read:ElementRef}) category :ElementRef;
  @ViewChild('search_dropdown') search_dropdown :ElementRef;
  @ViewChild('location_dropdown') location_dropdownn :ElementRef;
  @ViewChild('locations') locations :ElementRef;

  // autocompleteInput: string;
  // @Input() adressType: string;
  @ViewChild('addresstext') addresstext: ElementRef;
  active_dropdwon = 0;
  constructor(private menuCtrl: MenuController, private navCtrl: NavController, private platform: Platform, public db: DbService,private alertCtrl:AlertController,private router:Router,private modalCtrl:ModalController,private eRef:ElementRef,private renderer2: Renderer2, public zone: NgZone,private scroller: ViewportScroller,) {
    // this.renderer2.listen('window', 'click', (e: Event) => {
      // if ( (this.search_dropdown && this.search_dropdown.nativeElement.contains(e.target))  ) {
            // Clicked inside plus preventing click on icon
            // console.log("inside the area...")
        //  } else {
          // console.log("outside the area...")
      //  }
    // });
   }

   backbtn(){
    this.back_route == '/tabs/home' ? this.navCtrl.navigateRoot('/tabs/home') : null
   }


  ngOnInit() {
    this.db.ismobile = this.db.checkmobile();
    // this.drop_down3 =  true;
  }


  @HostListener('document:click', ['$event'])
  clickout(event) {

    this.search_dropdown && this.search_productss.length != 0 && !this.search_dropdown.nativeElement.contains(event.target) ? (this.search_productss = [] , this.keyword ='') : null      

    
    // if(!this.location_dropdown){
    //   this.location_container && this.db.locationpopup && !this.location_container.nativeElement.contains(event.target) ? this.db.locationpopup = false : null
    // } else {
    //   this.location_container && this.db.locationpopup && !this.location_container.nativeElement.contains(event.target) ? this.location_dropdown = false : null
    // }
    // if(){
    //   if() {
    //       if(this.location_dropdown ){
    //           // console.log(this.locations.nativeElement.contains(event.target))
              
    //       }
    //       // && this.locations && !this.locations.nativeElement.contains(event.target)
    //       //  else {
    //           // this.location_dropdown = false
    //       // }
    //   } else { 

    //     // if(this.location_dropdownn && this.location_dropdown && this.location_dropdownn.nativeElement.contains(event.target)) {
    //     //   // 
    //     // } else {
    //     //   this.db.locationpopup = false
    //     // }
    //   }
    // }

    // if(this.location_dropdownn && this.location_dropdown){
    //   if(this.location_dropdownn.nativeElement.contains(event.target)) {
    //   } else {
    //     this.location_dropdown = false
    //   }
    // }

    // if(this.locations && this.location_dropdown){
    //   if(this.locations.nativeElement.contains(event.target)) {
    //   } else {
    //     this.location_dropdown = false
    //   }
    // }
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event) { 
    if(this.search_down && this.search_productss.length != 0){
      if(event.key == 'ArrowDown'){ 
        (this.search_productss.length - 1 ) >  this.active_dropdwon ?  this.active_dropdwon += 1 : null
        // 
      } else if(event.key == 'ArrowUp') {
        this.active_dropdwon != 0 ?  this.active_dropdwon -= 1 : null
        // this.searching_down.nativeElement.scrollIntoView({behavior: "smooth", block: "start",inline: "nearest"});
      }else if( event.key == 'Enter'){

      }

    // if(document.getElementById('container_div_id').scrollHeight >document.getElementById('container_div_id').clientHeight ){
         document.getElementById(this.active_dropdwon+'s').scrollIntoView({ behavior: "smooth", block: "start",  inline: "nearest" });
    // }
      
      // this.searching_down.nativeElement.scrollIntoView({behavior: "smooth", block: "start",inline: "nearest"});
    }
    
    if(this.location_dropdown && this.db.location_sugesstions.length != 0){
      if(event.key == 'ArrowDown'){ 
        (this.db.location_sugesstions.length - 1)>  this.active_dropdwon ?  this.active_dropdwon += 1 : null
      } else if(event.key == 'ArrowUp') {
        this.active_dropdwon != 0 ?  this.active_dropdwon -= 1 : null
        // this.searching_down.nativeElement.scrollIntoView({behavior: "smooth", block: "start",inline: "nearest"});
      } else if( event.key == 'Enter'){
        this.db.validate_location(this.db.location_sugesstions[ this.active_dropdwon])
        this.db.location_sugesstions = []
      }
      document.getElementById(this.active_dropdwon+'d').scrollIntoView({ behavior: "smooth", block: "start",  inline: "nearest" });
      // this.searching_down.nativeElement.scrollIntoView({behavior: "smooth", block: "start",inline: "nearest"});
    }


    // let key = event.key;
    // let current_active = this.active;
    // if(key=='ArrowDown'){      
    //     if((current_active + 1) < this.db.category.length){
    //       this.active = current_active + 1;
    //       // this.goto(this.db.category[this.active],'child-1',this.active);          
    //     }
    // }else if(key=='ArrowUp'){
    //     if((current_active - 1) < this.db.category.length && (current_active-1) != -1){
    //       this.active = current_active - 1;
    //       // this.goto(this.db.category[this.active],'child-1',this.active);          
    //     }
    // }
  }

  // @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    // console.log(event);
    // if(this.db.locationpopup){
    //     this.db.locationpopup = false
    // }
//  }


  openMenu() {
    this.menuCtrl.open();
  }

  close_menu() {
    this.menuCtrl.close();
  }


  hide_dropdown2(event) {
    // console.log("working..")
    const xTouch = event.clientX;
    const yTouch = event.clientY;
    const rect = this.dropdown2.nativeElement.getBoundingClientRect();
    const topBoundary = rect.top + 2;
    const leftBoundary = rect.left + 2;
    const rightBoundary = rect.right - 2;
    if (xTouch < leftBoundary || xTouch > rightBoundary || yTouch < topBoundary) {
      this.drop_down2 = false;
    }
  }


  // hide_dropdown3(event) {
  //   // console.log("working..")
  //   this.search_area = false;
  //   const xTouch = event.clientX;
  //   const yTouch = event.clientY;
  //   const rect = this.location_container.nativeElement.getBoundingClientRect();
  //   const topBoundary = rect.top + 2;
  //   const leftBoundary = rect.left + 2;
  //   const rightBoundary = rect.right - 2;
  //   if (xTouch < leftBoundary || xTouch > rightBoundary || yTouch < topBoundary) {
  //     this.db.locationpopup = false;
  //   }
  // }


  main_product(event){
    const xTouch = event.clientX;
    const yTouch = event.clientY;
    const rect = this.mainproduct.nativeElement.getBoundingClientRect();
    const topBoundary = rect.top;
    const leftBoundary = rect.left + 2;
    const rightBoundary = rect.right - 2;
    const bottomBoundary = rect.top;
    //     console.log("x",xTouch);
    // console.log("y",yTouch)
    // console.log(rect)
    if (yTouch < topBoundary) {
      this.product_drop_down = false;
    }
  }


  hide_(event) {
    const xTouch = event.clientX;
    const yTouch = event.clientY;
    const rect = this.child_productdown.nativeElement.getBoundingClientRect();
    const topBoundary = rect.top + 2;
    const leftBoundary = rect.left + 2;
    const rightBoundary = rect.right - 2;
    const bottomBoundary = rect.bottom ;
    // console.log("x",xTouch);
    // console.log("y",yTouch);
    // console.log(rect)
    if (xTouch < leftBoundary || yTouch > bottomBoundary ) {
      this.product_drop_down = false
    }
  }


  hide_all_categories(event){
    const xTouch = event.clientX;
    const yTouch = event.clientY;
    const rect = this.all_categories.nativeElement.getBoundingClientRect();
    const topBoundary = rect.top + 2;
    const leftBoundary = rect.left + 2;
    const rightBoundary = rect.right - 2;
    const bottomBoundary = rect.bottom ;
    if (yTouch < bottomBoundary ) {
      this.all_category = false
    }
  }



  goto(route,child_no,type){
    // console.log(this.db.childs)
    if(child_no == 'child-1'){
        this.child_drop_down = false;
        this.db.childs.child_2_name = undefined
        this.db.childs.child_3_name = undefined
        if(type == 'click'){
          this.db.childs.child_1_name = route.category_name;
        }
        // this.db.childs.child_1 = route.route;
        // this.db.childs.child_2 = undefined
        // this.db.childs.child_3 = undefined
    } else if(child_no == 'child-2'){


       if(type == 'click'){
          this.db.childs.child_3 = undefined;
          this.db.childs.child_3_name = undefined;
          this.db.childs.child_2 = route.route;
          this.db.childs.child_2_name = route.category_name;
       }
      //  this.product_drop_down = false;

    }else if(child_no == 'child-3'){
       this.db.childs.child_3 = route.route;
       this.db.childs.child_3_name = route.category_name;
    }

    if(route.default_view && type == 'click'){
      this.db.view = route.default_view;
      this.all_category = false;
    }

    // this.router.navigateByUrl('/category/'+route.route)
  }

  @HostListener('window:resize', ['$event'])
  private func(){
     this.db.ismobile = this.db.checkmobile();
  }




  _menu = [
    {
      title: 'My Profile',
      route: '/my-profile/dashboard'
    },
    {
      title: 'My Orders',
      route: '/my-profile/my-orders'
    },
  ]


  searchproduct(d) {

    if (this.keyword != '' && this.keyword != undefined) {
      // this.router.navigateByUrl('/search/' + this.keyword);
      this.search_productss = []
      // this.router.navigate(['/search'], { queryParams: { q: this.keyword } });
    }
  }

  changelocation(){

  }

  currentlocation(){
    // this.db.locationpopup = false;
    this.db.location = undefined;
    // (fetch_location)=""
    // this.fetch_location.emit();
    this.db.current_locate()
  }

  checkOverflow (element) {
    return element.offsetHeight < element.scrollHeight ||  element.offsetWidth < element.scrollWidth;
  }

  scroll(direction){
    if (direction == 'next') {
        this.category.nativeElement.scrollLeft -= 350;
    } else if (direction == 'prev') {
        this.category.nativeElement.scrollLeft += 350;
    }
  }

  scrolled(event){
    if(event.target.scrollLeft != 0){
      this.category_left_arrow = true;
    }else if(event.target.scrollLeft == 0){
      this.category_left_arrow = false;
    }
  }


  // search_places(event){
  //   console.log(event.detail.value);
  //   this.db.search_places(event.detail.value).subscribe(res =>{
  //     console.log(res.results);
  //   })
  // }


  choosed_location(location){
    // console.log("TEsting...")
    this.db.city = location;
    // this.db.locationpopup = false;
  }

  menu_categories =[

    {
      text: 'Why Meatton',
      route: '/why'
    },
    {
      text: 'Our Story',
      route: '/story'
    },
    {
      text: 'About Us',
      route: '/about-us'
    },
    {
      text : 'Contact Us',
      route:'/contact-us'
    }
  ]


  searchproducts(d) {
    if (this.keyword != '' && this.keyword != undefined) {
      var data = { "searchTxt": this.keyword, page_no: 1, page_len: 15 }
      setTimeout( ()=>{
        this.db.search_products(data).subscribe(data => {
            // console.log(data)
            data && data.message && data.message.length != 0 ?   this.search_productss = data.message :    this.search_productss = [];
        }, error => {
          console.log(JSON.stringify(error.json()));
        });
      },100)

    } else {
        setTimeout( () => {  this.search_productss = [];},100)
    }

  }


 async ngAfterViewInit() {
      // console.log(this.db.locationpopup)
      // if(this.db.locationpopup){
      // await  this.getPlaceAutocomplete('');
      // }
  }
  

  GoogleAutocomplete;
  getPlaceAutocomplete(event) {
    console.log(this.keyword)

    
    console.log("loading loading..")
    this.GoogleAutocomplete = new google.maps.places.AutocompleteService();
    this.GoogleAutocomplete.getPlacePredictions({ input: this.keyword, componentRestrictions: {country: 'IN'} },
    (predictions, status) => {
      // this.autocompleteItems = [];
      this.zone.run(() => {
        predictions.forEach((prediction) => {
         console.log(prediction)
        });
      });
    });


    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
        {
            componentRestrictions: { country: 'IN' },
            types: ['address']  // 'establishment' / 'address' / 'geocode'
        });
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            const place = autocomplete.getPlace();
            console.log(place)
        });
  }
   

  // invokeEvent(place: Object) {
  //   this.setAddress.emit(place);
  // }

   zip_code(zipcode){
      if(localStorage.zipcode && localStorage.zipcode == zipcode ){
          return true
      } 
    }


    async open_Modal(){
      const modal = await this.modalCtrl.create({
        component : LocationPage,
        cssClass: (this.db.website_settings.location_areas && this.db.website_settings.location_areas.length != 0) ?"location-modal-locations" :"location-modal" 
      })
      this.db.modal_stacks.push('location-modal');
      await modal.present()
      await modal.onWillDismiss().then(res =>{
        this.db.modal_stacks = this.db.modal_stacks.filter(item => item !== 'location-modal')
      })
    }

  }
  
