import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { DbService } from 'src/app/services/db.service';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-location',
  templateUrl: './location.page.html',
  styleUrls: ['./location.page.scss'],
})
export class LocationPage implements OnInit {
  myform;
  autocompleteInput: string;
  @ViewChild('addresstext') addresstext: ElementRef;
  constructor(private formBuilder:FormBuilder,public db:DbService,private ngzone:NgZone,public modalCtrl:ModalController) { 
    this.db.scroll_event.desktop_header  = false
  }

  ngOnInit() {
    this.db.checkmobile();
  }

  ngAfterViewInit() {
    this.db.getPlaceAutocomplete(this.addresstext.nativeElement)
}

  getPlaceAutocomplete() {
    // console.log(event)
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
        {
          componentRestrictions: { country: 'IN' },
          types: ['establishment']  // 'establishment' / 'address' / 'geocode'
        });

        // console.log(autocomplete)
        google.maps.event.addListener(autocomplete, 'place_changed', (res) => {
          console.log(res)
            const place = autocomplete.getPlace();
            console.log(place);
            this.db.validate_location(place)
        });
     let  GoogleAutocomplete = new google.maps.places.AutocompleteService();
        GoogleAutocomplete.getPlacePredictions({ input: this.autocompleteInput,componentRestrictions: {country: 'IN'}  },
        (predictions, status) => {
          this.ngzone.run(() => {
            predictions.forEach((prediction) => {
               console.log(prediction)
             //this.autocompleteItems.push(prediction);
            });
          });
      });  
   }


   zip_code(zipcode){
    if(localStorage.zipcode && localStorage.zipcode == zipcode ){
        return true
    } 
  }

  currentlocation(){
    // this.db.locationpopup = false;
    this.db.location = undefined;
    // (fetch_location)=""
    // this.fetch_location.emit();
    this.db.current_locate()
  }
}
