import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';

import { RegisterPage } from 'src/app/pages/register/register.page';
import { DbService } from 'src/app/services/db.service';

import {
  AlertController,
  LoadingController,
  MenuController,
  ModalController,
} from '@ionic/angular';

import { DeliverySlotComponent } from '../delivery-slot/delivery-slot.component';

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-your-cart',
  templateUrl: './your-cart.component.html',
  styleUrls: ['./your-cart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class YourCartComponent implements OnInit {
  @Input() load;
  @Input() no_title;
  @Input() no_footer;
  
  constructor(public db: DbService,private menuCtrl:MenuController,private loadingCtrl:LoadingController,private router:Router,private modalCtrl:ModalController,private alertCtrl:AlertController) {
    this.db.ismobile = this.db.checkmobile();
    if(this.db.ismobile && db.cust_name){
      this.db.getShippingMethods();
    }
    this.db.cancel_promo();
  }

  ngOnInit() {
        this.db.selected_slot =[];
        this.db.shipping_settings.shipping_charges = 0
        this.db.shipping_settings.selected_shipping_method = undefined
        this.db.shipping_settings.selected_shipping_method_name = undefined
        this.db.selected_slot_label = {}
        this.db.delivery_slots = [];
        this.db.selected_slot = [];
        this.db.get_cart_item();
   }

  // get_cart_items(){
        
  // }

  close_menu() {
    this.menuCtrl.close();
  }


 async addtocart(list) {
  // var data = {
  //   "product": list.product,
  //   "attribute_id":  list.attribute_ids,
  //   "cart_qty": list.quantity,
  //   "variant_html" : list.attribute_description
  // }
  // ProductId, attributeId=None, add_qty=None, qty=None, customer=None
  // product, qty, attribute_ids=None, attribute_description=None
  if(list && !list.no_stock){
      var data = {
        "ProductId": list.product,
        "attributeId":  list.attribute_ids,
        "qty": this.db.plusCount(list.quantity),
        // "attribute_description" : list.attribute_description
      }
      this.db.validate_product_cart_qty(data).subscribe(data => {
        if(data.message && data.message.status == true){
            this.update(list);
        } else {
          // console.log(data);
          this.db.cart_items.map(res =>{
              if(res.product == list.product){
                    res.no_stock = true
              }
          })
          // console.log(this.db.cart_items)
          // list.no_stock = true;
        }
      })
  }
}


update(list){
  var datas = {  name: list.name, qty: this.db.plusCount(list.quantity),  qty_type:"" }
  this.db.update_cart_items(datas).subscribe(data => {
     if(data.message.status == 'error'){
       var d = JSON.parse(data._server_messages);
       var d1 = JSON.parse(d);
       this.db.alert(d1.message)
     } else {
       this.db.get_cart_item();
     }
  }, error => { console.log(JSON.stringify(error.json())); });
}


  //Remove Cart
  async remove(list,type) {
      const alert = await this.alertCtrl.create({
        subHeader:type == 'remove'?'Are you sure want to remove the product?':'Are you sure want to delete the product?',
        buttons:[
          {
            text:'Cancel',

          },
          {
            text:'Ok',
            handler: () => {
              if(type == 'remove'){
                this.remove_cart(list)
              }else if(type == 'delete'){
                this.delete_cart(list)
              }
            }
          }
        ]
      })

      if(type == 'remove' ){
        this.remove_cart(list)
      } else {
        await alert.present();
      }

  }




  remove_cart(list){
    var datas={  name: list.name, qty: this.db.removeCount(list.quantity), qty_type:"" }
    if(datas.qty == 0){
      this.remove(list,'delete')
      // this.delete_cart(list);
    }else{

      this.db.update_cart_items(datas).subscribe(data => {
        this.db.get_cart_item();
            // this.db.sub.next('cart emitted');
      }, error => {console.log(JSON.stringify(error.json())); });
   }
  }


 async delete_cart(list) {
    var datass={  name:list.name,  customer:localStorage.customerRefId, user:"" }
    this.db.delete_cart_items(datass).subscribe(data => {
       if(data._server_messages){
         var b = JSON.parse(data._server_messages);
         var b1 = JSON.parse(b);
         this.db.alert(b1.message)
       }
      //  this.db.sub.next('cart emitted');
      this.db.get_cart_item();
    }, error => {
      console.log(JSON.stringify(error.json()));
    });
 }

  async  check_stocks(){
    let data = { customer_id : localStorage.customerRefId }
      this.db.check_cartitems_stock_mob(data).subscribe(res =>{
              if(res.message.status){
                if(!this.db.cust_name){
                       this.open_modal();
                }else{
                  this.db.delivery_slots = [];
                  this.db.selected_slot = [];
                  this.db.shipping_settings && this.db.shipping_settings.selected_shipping_method ? this.db.shipping_settings.selected_shipping_method = undefined : null
                  // this.db.ismobile ? this.db.shipping_methods  = [
                  this.router.navigateByUrl('/checkout');
                }
              } else {
                this.db.alert(res.message.message);
              }
          })
  }


    async open_modal(){
          const modal = await this.modalCtrl.create({
            component: RegisterPage,
            cssClass:'auth-popup',
            componentProps: {
              type: 'login'
            }
        })
        this.db.modal = true
        await modal.present();
        await modal.onWillDismiss().then( res =>{
          this.db.modal_popup = true;
          this.db.opened_login_modal = false;
          // this.db.get_address();
          // this.db.get_cart_item();
          // if(this.db.ismobile && this.db.cust_name){
          //   this.db.getShippingMethods();
          // }
          this.db.modal = false;
          this.router.navigateByUrl('/checkout')
        });
    }



   async clearcart(){
    let alert = await this.alertCtrl.create({
      header:"Alert",
      message:'Are you sure to clear all cart items?',
      buttons:[
        {
          text :'Cancel',
          handler:() =>{ }
        },
        {
          text:'Ok',
          handler:() =>{
            let data = { customer : localStorage.customerRefId }
            this.db.clear_cartitem(data).subscribe(res =>{
              // console.log(res)
              this.db.get_cart_item();
            })
          }
        }
      ]
    })

    await alert.present();
   }


   ionViewWillDismiss(){
      this.db.selected_slot = [];
   }


   async open_delivery_slot(){
    // this.db.selected_slot =[];
    // this.db.shipping_settings.selected_shipping_method = undefined
    // this.db.delivery_slots = [];
       const modal  = await this.modalCtrl.create({
         component:DeliverySlotComponent,
         cssClass:'actionsheet-popup',
         swipeToClose : true,
        //  componentProps:
       })
       await modal.present();
       
       let data = await modal.onWillDismiss();
      
       if(data){  
          if(this.db.delivery_slots.length != 0){
            this.db.delivery_slots.map(value =>{
              if(value.selected_time_slots){
                let d = {
                  category : value.category,
                  date : value.selected_time_slots.date,
                  from_time : value.selected_time_slots.selected_time.from_time,
                  to_time :  value.selected_time_slots.selected_time.to_time,
                  // time_format : value.selected_time_slots.selected_time.time_format
                }
                // let y =  {
                //   date : value.format_date,
                //   time : value.selected_time.time_format
                // }
                this.db.selected_slot_label.date =  value.selected_time_slots.format_date
                this.db.selected_slot_label.time =  value.selected_time_slots.selected_time.time_format
                // console.log(this.db.selected_slot_label);
                this.db.selected_slot.push(d);
              }
            })
            // console.log(this.db.selected_slot)    
          }
       }
   }  


   check_methods(){
      let temp = false;
      if(this.db.shipping_settings && this.db.shipping_settings.selected_shipping_method_name){
          if(this.db.delivery_slots.length != 0){
            this.db.selected_slot.length != 0 && this.db.selected_address && this.db.selected_address.name ? temp = true : null
          } else {
            return temp = true;
          }
      }
      return temp;
   }


}
